const passwordValidation = (text: string, difficult: string = 'easy') => {
  const has8PlusChars = checkHas8PlusChars(text);
  const hasUppercase = checkHasUppercase(text);
  const hasSpecial = checkHasSpecial(text);
  const hasNoSequencial = checkHasNoSequencialChars(text);

  return (
    has8PlusChars &&
    hasUppercase &&
    hasSpecial &&
    ((difficult == 'hard' && hasNoSequencial) || difficult == 'easy')
  );
};

export const checkHas8PlusChars = (text: string) => {
  return text.length >= 8;
};

export const checkHasUppercase = (text: string) => {
  const chars = Array.from(text);
  return chars.some((char) => /[A-Z]/.test(char));
};

export const checkHasSpecial = (text: string) => {
  const chars = Array.from(text);
  return chars.some((char) => /[!@#$%^&*(),.?":{}|<>]/.test(char));
};

export const checkHasNoSequencialChars = (text: string) => {
  const regexp = new RegExp(
    /(?:012|123|234|345|456|567|678|789|890|987|876|765|654|543|432|321|210)/,
  );
  return !regexp.test(text);
};

export default passwordValidation;
