import { ITranslationCountryType } from './types';

const TranslationCL: ITranslationCountryType = {
  'error-verify-email': 'Verifica tu e-mail',
  'error-verify-pass': 'Verifica tu contraseña',
  'title-manual-registration': 'Ingresá o regístrate',
  'error-title-submit-manual': 'Los datos son incorrectos',
  'error-message-submit-manual': 'Revisa la información suministrada.',
  'login-manual-label-login': 'E-mail',
  'login-manual-label-password': 'Contraseña',
  'login-manual-button-submit-text': 'Ingresar',
  'login-manual-button-forgot-password': '¿Olvidaste tus datos de acceso?',
  'login-manual-button-create-account': 'Crear cuenta',

  'login-social-title': 'Ingresá con red social',
  'login-social-button-facebook': 'Login con Facebook',
  'login-social-button-apple': 'Login con Apple',
  'login-social-button-google': 'Login con Google',
  'login-social-message':
    'Agrega una cuenta de cada red para acceder a Dafiti.',
  'login-social-associate-title': 'Hola, no encontramos tu cuenta en Dafiti.',
  'login-social-associate-description':
    'Si tienes una cuenta en Dafiti, inicia sesión al lado para asociarla o regístrate.',
  'login-social-associate-cancel': 'Intentar con otra red social',
  'login-social-associate-error-title': 'Error al asociar',
  'login-social-associate-error-desc':
    'Intenta con otra cuenta de Dafiti o red social.',

  'forgot-password-my-password': '¿Olvidaste tus datos de acceso?',
  'forgot-password-help-change': 'Vamos a ayudarte a recuperar tu contraseña',
  'forgot-password-form-email': 'Ingresa tu e-mail registrado',
  'forgot-password-form-validate': 'Verifica su e-mail',
  'forgot-password-redefine': 'Recuperar contraseña',
  'forgot-password-send-email':
    'Se ha enviado un correo electrónico a “email-sent” con el link para restablecer tu nueva contraseña. <br/>No olvides revisar el spam.',
  'forgot-password-error-service': 'Ha ocurrido un error. Inténtelo más tarde.',

  'reset-pass-new-pass': 'Ingresá una nueva contraseña',
  'reset-pass-save-pass': 'Guardar nueva contraseña',

  'pass-validator-password-fail':
    'Su contraseña debe cumplir con todos los requisitos a continuación',
  'pass-validator-label-password': 'Contraseña',

  'pass-rule-title': 'Tu contraseña debe contener como mínimo',
  'pass-rule-8-chars': 'Mínimo de 8 caracteres',
  'pass-rule-1-uppercase': '1 letra mayúscula',
  'pass-rule-1-special-char': '1 caracter especial, como ?-!*$#',
  'pass-rule-1-sequencial-char': 'Números secuenciales, como 123',

  'login-registration-natural-person': '** Pessoa física',
  'login-registration-legal-person': '** Pessoa jurídica',
  'login-registration-fullname': 'Nombre y apellido',

  'login-registration-cellphone': 'Celular',
  'login-registration-cpf': 'CPF',
  'login-registration-cnpj': 'CNPJ',
  'login-registration-rut': 'RUT',
  'login-registration-email': 'E-mail personal',
  'login-registration-birth': 'Fecha de nacimiento (dd/mm/aaaa)',
  'login-registration-password': 'Contraseña',
  'login-registration-store-pref': 'Seleciona tu preferencia',
  'login-registration-accept-newsletter':
    'Acepto recibir noticias, promociones e información por e-mail.',
  'login-registration-accept-registration':
    'Acepto <a target="_blank" href="https://www.dafiti.cl/politica-de-privacidad/">política y tratamiento</a> de mis datos',
  'login-registration-h1ave-account': '¿Ya tienes una cuenta?',
  'login-registration-click-enter': 'Clic aquí para ingresar',

  'login-registration-female-style': 'Femenino',
  'login-registration-male-style': 'Masculino',
  'login-registration-only-letters': 'Ingresa solo letras',
  'login-registration-add-surname': 'Introduce también tu apellido',
  'login-registration-check-phone': 'Verifica tu número de celular',
  'login-registration-check-taxid': 'Documento inválido, revisa la información',
  'login-registration-check-email': 'E-mail inválido, revisa la información',
  'login-registration-check-birth':
    'Complete la fecha de nacimiento (dd/mm/aaaa)',

  'login-registration-over-eighteen':
    'Você deve ter mais de 18 anos para continuar',
  'login-registration-check-password':
    'Su contraseña debe cumplir con todos los requisitos a continuación',
  'login-registration-check-preferences': 'Debe seleccionar su preferencia',
  'login-registration-account-exists-title': 'Cuenta exitente',
  'login-registration-account-exists-desc':
    'Parece que ya tienes una cuenta activa en Dafiti.<br/>Puede ingresar su e-mail o recuperar su acceso.',
  'login-registration-account-exists-restore-pass': 'Recuperar contraseña',

  'footer-help-support': 'Ayuda y apoyo',
  'footer-policy-delivery-time': 'Política de Privacidad | Plazos de entrega',
  'footer-policy-exchange-refund': 'Cambios y devoluciones',
  'footer-call-center': 'Atención al público',
  'footer-service': '',
  'footer-service-number': 'BOG 508 2244',
  'footer-security-certificate': 'Seguridad y Certificado',
  'footer-secure-website': 'Sitio web seguro con cifrado (SSL)',
  'footer-usertrust': 'Aprobado por la red USERTrust',
  'footer-all-rights-reserved':
    '© Dafiti 2011 - 2024, Todos los derechos reservados.',
  'footer-policy-privacy':
    'Lea nuestra <a href="https://www.dafitigroup.com/politica-de-privacidade">política de privacidad<a>.',

  'header-back': 'Volver',
};

export default TranslationCL;
