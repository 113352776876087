import { ITranslationCountryType } from './types';

const TranslationBR: ITranslationCountryType = {
  'error-verify-email': 'Verifique o e-mail ou CPF',
  'error-verify-pass': 'Verifique sua senha',
  'title-manual-registration': 'Entre ou cadastre-se',
  'error-title-submit-manual': 'Dados incorretos',
  'error-message-submit-manual': 'Revise as informações fornecidas',
  'login-manual-label-login': 'E-mail ou CPF',
  'login-manual-label-password': 'Senha',
  'login-manual-button-submit-text': 'Entrar',
  'login-manual-button-forgot-password': 'Esqueceu seus dados de acesso?',
  'login-manual-button-create-account': 'Criar conta',

  'login-social-title': 'Entrar com rede social',
  'login-social-button-facebook': 'Login com Facebook',
  'login-social-button-apple': 'Login com Apple',
  'login-social-button-google': 'Login com Google',
  'login-social-message':
    'Associe uma conta de cada rede para acessar a Dafiti.',
  'login-social-associate-title': 'Olá, não encontramos sua conta na Dafiti.',
  'login-social-associate-description':
    'Se você possui uma conta na Dafiti, faça o login ao lado para associá-lo ou cadastre-se.',
  'login-social-associate-cancel': 'Tentar com outra rede social',
  'login-social-associate-error-title': 'Erro ao associar',
  'login-social-associate-error-desc':
    'Tente com outra conta Dafiti ou rede social.',

  'forgot-password-my-password': 'Esqueceu seus dados de acesso?',
  'forgot-password-help-change': 'Vamos te ajudar a recuperar sua senha.',
  'forgot-password-form-email': 'E-mail ou CPF/CNPJ cadastrado',
  'forgot-password-form-validate': 'Verifique o e-mail ou CPF/CNPJ',
  'forgot-password-redefine': 'Redefinir senha',
  'forgot-password-send-email':
    'Um e-mail foi enviado para “email-sent” com o link para você redefinir sua senha. <br/>Não esqueça de checar o spam.',
  'forgot-password-send-cpf':
    'Um e-mail foi enviado com o link para você redefinir sua senha. <br/>Não esqueça de checar o spam.',
  'forgot-password-error-service':
    'Um erro ocorreu.  Por favor, tente mais tarde.',

  'reset-pass-new-pass': 'Cadastrar nova senha',
  'reset-pass-save-pass': 'Salvar nova senha',

  'pass-validator-password-fail':
    'Sua senha deve cumprir todos os requisitos abaixo',
  'pass-validator-label-password': 'Senha',

  'pass-rule-title': 'Sua senha deve conter no mínimo:',
  'pass-rule-8-chars': 'Mínimo de 8 caracteres',
  'pass-rule-1-uppercase': '1 letra maiúscula',
  'pass-rule-1-special-char': '1 caractere especial, como ?-!*$#',
  'pass-rule-1-sequencial-char': 'Números sequenciais, como 123',

  'login-registration-natural-person': 'Pessoa física',
  'login-registration-legal-person': 'Pessoa jurídica',
  'login-registration-name': 'Nome',
  'login-registration-fullname': 'Nome e sobrenome',
  'login-registration-cellphone': 'Celular',
  'login-registration-cpf': 'CPF',
  'login-registration-cnpj': 'CNPJ',
  'login-registration-state-registration': 'Inscrição Estadual',
  'login-registration-state-registration-exp': 'I.E isento',
  'login-registration-email': 'E-mail pessoal',
  'login-registration-birth': 'Data de Nascimento (dd/mm/aaaa)',
  'login-registration-password': 'Senha',
  'login-registration-store-pref': 'Selecione sua preferência',
  'login-registration-accept-newsletter':
    'Aceito receber novidades, promoções e informações por e-mail.',
  'login-registration-accept-registration':
    'Ao se cadastrar, você se declara ciente e concorda com as nossas <a target="_blank" href="https://www.dafitigroup.com/politica-de-privacidade">políticas de privacidade</a>.',
  'login-registration-have-account': 'Já tem uma conta?',
  'login-registration-click-enter': 'Clique aqui para entrar',

  'login-registration-female-style': 'Moda feminina',
  'login-registration-male-style': 'Moda masculina',
  'login-registration-only-letters': 'Utilize apenas letras',
  'login-registration-add-surname': 'Digite também seu sobrenome',
  'login-registration-check-name': 'Verifique seu nome',
  'login-registration-check-phone': 'Verifique o número do celular',
  'login-registration-check-taxid': 'CPF inválido, revise a informação',
  'login-registration-check-cnpj': 'CNPJ inválido, revise a informação',
  'login-registration-check-email': 'E-mail inálido, revise a informação',
  'login-registration-check-state-registration':
    'I.E inválida, revise a informação',
  'login-registration-check-birth':
    'Preencha a data de nascimento completa dd/mm/aaaa',
  'login-registration-over-eighteen':
    'Você deve ter mais de 18 anos para continuar',
  'login-registration-check-password':
    'Sua senha deve cumprir todos os requisitos abaixo',
  'login-registration-check-preferences':
    'Você deve selecionar sua preferência',
  'login-registration-account-exists-title': 'Conta já existente',
  'login-registration-account-exists-desc':
    'Parece que você já tem uma conta ativa na Dafiti.<br/>Você pode entrar com o e-mail, CPF ou CNPJ ou recuperar a sua senha.',
  'login-registration-account-exists-restore-pass': 'Recuperar senha',

  'footer-help-support': 'Ajuda e Suporte',
  'footer-policy-delivery-time': 'Política de Privacidade | Prazo de Entrega',
  'footer-policy-exchange-refund': 'Política de Trocas e Devoluções',
  'footer-call-center': 'Central de atendimento',
  'footer-service': 'Atendimento',
  'footer-service-number': '(11) 3053 7500',
  'footer-security-certificate': 'Segurança e Certificado',
  'footer-secure-website': 'Site seguro com criptografia (SSL)',
  'footer-usertrust': 'Homologado pela USERTrust Network',
  'footer-all-rights-reserved':
    '© Dafiti 2011 - 2024, Todos os direitos reservados.',
  'footer-policy-privacy':
    'Conheça nossa <a href="https://www.dafitigroup.com/politica-de-privacidade">Política de Privacidade<a>.',

  'header-back': 'Voltar',
};

export default TranslationBR;
