import React from 'react';
import './popup.css';

interface ReactFunPopupContent {
  closePopupFunction: () => void;
}

const Popup: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  PopupContent: React.FC<ReactFunPopupContent>;
}> = ({ isOpen, onClose, PopupContent }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={onClose} role="presentation">
      <div
        className="popup-content"
        onClick={(e) => e.stopPropagation()}
        role="presentation"
      >
        <button className="close-popup-button" onClick={onClose} />
        <PopupContent closePopupFunction={onClose} />
      </div>
    </div>
  );
};

export default Popup;
