import { useState } from 'react';
import useTranslation from '../../utils/translation';
import CreateAccountBRLegal from './CreateAccountBRLegal';
import CreateAccountBRNatural from './CreateAccountBRNatural';
import styles from './styles.module.css';
import stylesLogin from '../Login/styles.module.css';

const CreateAccountBR = () => {
  const COUNTRY = 'BR';
  const translate = useTranslation(COUNTRY);

  const [personType, setPersonType] = useState('N');

  const handleOnChangePersonType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonType(e.target.value);
  };

  return (
    <div className={styles.registration}>
      <h1 className={stylesLogin.sectionTitle}>
        {translate['title-manual-registration']}
      </h1>
      <div className={styles.personType}>
        <label className={styles.personLabel}>
          <input
            type="radio"
            name="person"
            value="N"
            onChange={(e) => handleOnChangePersonType(e)}
            checked={personType === 'N'}
          />
          {translate['login-registration-natural-person']}
        </label>
        <label className={styles.personLabel}>
          <input
            type="radio"
            name="person"
            value="L"
            onChange={(e) => handleOnChangePersonType(e)}
            checked={personType === 'L'}
          />
          {translate['login-registration-legal-person']}
        </label>
      </div>
      <div>
        {personType === 'L' ? (
          <CreateAccountBRLegal />
        ) : (
          <CreateAccountBRNatural />
        )}
      </div>
    </div>
  );
};

export default CreateAccountBR;
