import { useConfig } from './ConfigProvider';

declare global {
  interface Window {
    InstanaEumObject: string | undefined; // eslint-disable-next-line
    ineum(...args: any[]): void;
  }
}

if (!window.InstanaEumObject) {
  window.InstanaEumObject = 'ineum'; // eslint-disable-next-line
  const n: any = (window.ineum = function () {
    // eslint-disable-next-line
    return n.q.push(arguments);
  });
  n.q = [];
  n.v = 2;
  n.l = Date.now();
}

export const ievent = (page: string, event: string) => {
  if (window['ineum']) {
    window.ineum(page, event);
  }
};

export const { ineum } = window;

// eslint-disable-next-line
export default () => {
  const config = useConfig();

  ineum('reportingUrl', 'https://eum-orange-saas.instana.io');
  ineum('key', config ? config.REACT_APP_INSTANA_KEY : '');
  ineum('trackSessions');
  ineum('wrapEventHandlers', true);
  ineum('ignoreErrorMessages', [/^script error/i]);

  const script = document.createElement('script');
  script.defer = true;
  script.crossOrigin = 'anonymous';
  script.type = 'text/javascript';
  script.src = 'https://eum.instana.io/1.7.2/eum.min.js';
  script.integrity = config ? config.REACT_APP_INSTANA_INTEGRITY : '';
  document.head.appendChild(script);
};
