/* eslint-disable  @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import api from '../../api';

interface LALoginBody {
  grant_type: string;
  scope?: string;
  username?: string;
  password?: string;
  provider?: string;
  recaptcha?: string;
  fk_country?: string;
  token_login?: string;
}

interface LACreateUser {
  firstName: string;
  lastName?: string;
  email: string;
  taxIdentification?: string;
  identification?: string;
  identification_type?: string;
  stateRegistration?: string;
  stateRegistrationExp?: boolean;
  birthday?: string;
  password: string;
  isNewsLetterSubscribed?: string;
  phone?: string;
  gender?: string;
}

const saveLocal = (access_token: string, refresh_token: string) => {
  window.localStorage.setItem('access_token', access_token);
  window.localStorage.setItem('refresh_token', refresh_token);
  Cookies.set('access_token', access_token, {
    secure: true,
    path: '/',
    domain: getFinalDomain(window.location.href),
    sameSite: 'None',
    expires: 7,
  });

  return access_token;
};

const getFinalDomain = (url: string) => {
  const domain = new URL(url).hostname;
  const domainParts = domain.split('.');
  let finalDomain = domainParts.slice(-2).join('.');
  if (domainParts.includes('dafiti')) {
    const index = domainParts.indexOf('dafiti');
    finalDomain = domainParts.slice(index).join('.'); // Pega "dafiti" e o restante
  }

  return '.' + finalDomain;
};

const callLALogin = async (
  API_URL: string,
  body: LALoginBody,
): Promise<{
  access_token: string;
  refresh_token: string;
  expires_in: number;
  associated: boolean;
}> => {
  const { url, options } = api.TOKEN_POST(API_URL, body);
  const tokenResponse = await fetch(url, options);
  if (!tokenResponse.ok) {
    const json = await tokenResponse.json();

    throw new Error(`Error: ${json.message}`);
  }
  const { access_token, refresh_token, expires_in, associated } =
    await tokenResponse.json();

  return { access_token, refresh_token, expires_in, associated };
};

export async function userLogin(
  API_URL: string,
  username: string,
  password: string,
  recaptcha: string,
) {
  const body = {
    grant_type: 'password',
    username,
    password,
    recaptcha,
  };

  const { access_token, refresh_token, expires_in, associated } =
    await callLALogin(API_URL, body);

  saveLocal(access_token, refresh_token);

  return { access_token, refresh_token, expires_in, associated };
}

export async function socialLogin(
  API_URL: string,
  client_secret: string,
  provider: string,
) {
  const body = {
    grant_type: 'provider_credentials',
    provider: provider,
    client_secret: client_secret,
  };

  const { access_token, refresh_token, expires_in, associated } =
    await callLALogin(API_URL, body);

  saveLocal(access_token, refresh_token);

  return { access_token, refresh_token, expires_in, associated };
}

export async function associateLogin(
  API_URL: string,
  accessToken: string,
  socialToken: string,
) {
  const body = {
    access_token_social: socialToken,
    access_token_target: accessToken,
  };

  const { url, options } = api.SOCIAL_ASSOCIATION_POST(API_URL, body);

  const tokenResponse = await fetch(url, options);
  if (!tokenResponse.ok) {
    const json = await tokenResponse.json();

    console.error('um erro ocorreu ao associar a conta social a uma da dafiti');

    throw new Error(`Error: ${json.message}`);
  }
  return await tokenResponse.json();
}

export async function forgotPassword(API_URL: string, username: string) {
  const body = {
    username,
  };
  const { url, options } = api.FORGOT_PASSWORD_POST(API_URL, body);
  const tokenResponse = await fetch(url, options);
  if (!tokenResponse.ok) {
    const json = await tokenResponse.json();

    console.error(
      'um erro ocorreu ao solicitar resetpassword para login-adapter',
    );

    throw new Error(`Error: ${json.message}`);
  }
  return await tokenResponse.json();
}

export async function validateRestorePasswordKey(
  API_URL: string,
  restoreKey: string,
) {
  const body = {
    restore_password_key: restoreKey,
  };
  const { url, options } = api.VALIDATE_RESTORE_PASSWORD_KEY(API_URL, body);

  const tokenResponse = await fetch(url, options);
  if (!tokenResponse.ok) {
    const json = await tokenResponse.json();

    console.error(
      'um erro ocorreu ao solicitar VALIDATE_RESTORE_PASSWORD_KEY para login-adapter',
      tokenResponse,
    );

    throw new Error(`Error: ${json.message}`);
  }
  return await tokenResponse.json();
}

export async function resetPassword(
  API_URL: string,
  restorePasswordKey: string,
  username: string,
  password: string,
) {
  const body = {
    restore_password_key: restorePasswordKey,
    username: username,
    new_password: password,
    password_confirmation: password,
  };
  const { url, options } = api.RESTORE_PASSWORD(API_URL, body);

  const tokenResponse = await fetch(url, options);
  if (!tokenResponse.ok) {
    const json = await tokenResponse.json();

    console.error(
      'um erro ocorreu ao solicitar RESTORE_PASSWORD para login-adapter',
      tokenResponse,
    );

    throw new Error(`Error: ${json.message}`);
  }
  return await tokenResponse.json();
}

export async function createUser(API_URL: string, user: LACreateUser) {
  const body = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    tax_identification: user.taxIdentification,
    identification: user.identification,
    identification_type: user.identification_type,
    state_registration_exempt: user.stateRegistrationExp,
    state_registration: user.stateRegistration,
    birthday: user.birthday,
    password: user.password,
    is_newsletter_subscribed: user.isNewsLetterSubscribed,
    gender: user.gender,
    phone: user.phone,
    source: 'login-front',
  };

  const { url, options } = api.CREATE_USER(API_URL, body);

  const tokenResponse = await fetch(url, options);
  if (!tokenResponse.ok) {
    const json = await tokenResponse.json();

    console.error('error calling CREATE_USER');

    throw new Error(`Error: ${json.message}`);
  }
  return await tokenResponse.json();
}
