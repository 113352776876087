import styles from './styles.module.css';
import loading from './dzinho.svg';

const Loading = ({ visibled = false }: LoadingProps) => {
  return (
    <>
      {visibled && (
        <div className={styles.loading}>
          <img src={loading} alt="loading" />
        </div>
      )}
    </>
  );
};

interface LoadingProps {
  visibled?: boolean;
}

export default Loading;
