import styles from './styles.module.css';

const Button = ({
  id = null,
  text,
  disabled,
  type = 'button',
  onClick,
}: ButtonProps) => {
  let buttonBoxStyle = styles.buttonPrimary;
  let buttonTextStyle = styles.button1;
  if (disabled) {
    buttonBoxStyle += ' ' + styles.disabledButton;
    buttonTextStyle += ' ' + styles.disableTextButton;
  }
  if (type === 'submit') {
    buttonBoxStyle += ' ' + styles.submit;
    buttonTextStyle += ' ' + styles.submit;
  }

  return (
    <button
      {...(id ? { id } : {})}
      type={type}
      className={buttonBoxStyle}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={buttonTextStyle}>{text}</div>
    </button>
  );
};

interface ButtonProps {
  id?: string | null;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export default Button;
