import PropTypes from 'prop-types';

const Divider = ({
  className = 'class',
  pattern = 'dark',
  state = 'solid',
}) => {
  return <div className={`${className} ${pattern} ${state}`} />;
};

Divider.propTypes = {
  className: PropTypes.oneOf(['class', 'class-2', 'class-3']),
  pattern: PropTypes.string,
  state: PropTypes.string,
};

export default Divider;
