import { FunctionComponent } from 'react';
import styles from './styles.module.css';

const SocialButton: FunctionComponent<Button> = (props) => {
  const { text, provider, onClick } = props;

  const fistLetterUpperCase =
    provider.charAt(0).toUpperCase() + provider.slice(1);

  return (
    <button className={styles.buttonSecondary} onClick={onClick}>
      <img
        className={styles[`social${fistLetterUpperCase}`]}
        alt=""
        src={`/social--${provider}.svg`}
      />
      <div className={styles[`loginCom${fistLetterUpperCase}`]}>{text}</div>
    </button>
  );
};

interface Button {
  text: string;
  provider: string;
  onClick?: () => void;
}

export default SocialButton;
