/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, InputText, Loading } from '../../components';
import InputMasked from '../../components/InputMasked';
import InputPasswordRules from '../../components/InputPasswordRules';
import Popup from '../../components/Popup';
import RecaptchaContext from '../../hooks/RecaptchaContext';
import * as configProvider from '../../services/ConfigProvider';
import {
  pushPageViewDataLayer,
  sendErrorToGa,
} from '../../services/googleTagManager';
import { createUser, userLogin } from '../../services/Login';
import checkAutofill from '../../utils/helper/check-autofill';
import redirectHistory from '../../utils/redirect';
import useTranslation from '../../utils/translation';
import AccountExists from './AccountExists';
import {
  cellphoneValidator,
  cnpjValidator,
  emailValidator,
  nameValidator,
  passwordValidator,
  stateRegistrationValidator,
} from './FieldsValidator';
import styles from './styles.module.css';

interface CustomerLegal {
  name: string;
  cellphone: string;
  taxIdentification: string;
  stateRegistration: string;
  stateRegistrationExp: boolean;
  email: string;
  birthday: string;
  password: string;
  preferences: string;
  newsletter: boolean;
}

const CustomerLegalDefault = {
  name: '',
  cellphone: '',
  taxIdentification: '',
  stateRegistration: '',
  stateRegistrationExp: false,
  email: '',
  birthday: '',
  password: '',
  preferences: '',
  newsletter: true,
};

const CreateAccountBRLegal = () => {
  const config = configProvider.useConfig();
  const API_URL = configProvider.configGetLoginAdapterUrl(config);
  const localRedirect = configProvider.configGetAliceHost(config);
  const recaptchaPublicKey = configProvider.configGetRecaptchaKey(config);

  const [values, setValues] = useState<CustomerLegal>(CustomerLegalDefault);
  const [errors, setErrors] = useState<CustomerLegal>(CustomerLegalDefault);
  const [isValid, setIsValid] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const translate = useTranslation('BR');

  const recaptcha = useContext(RecaptchaContext);

  const renewRecaptchaToken = async () => {
    const token = await recaptcha.executeReCaptcha(recaptchaPublicKey);
    return token;
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    pushPageViewDataLayer('login - create', 'Registration BR Legal');

    setTimeout(() => {
      checkAutofill('styles_active__OmSYD');
    }, 1000);
  }, []);

  const handleCreateAccount = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    let isOk = true;
    for (const [key, value] of Object.entries(values)) {
      if (validateFormFields(key, value)) {
        isOk = false;
      }
    }

    if (isOk) {
      const user = {
        firstName: values.name,
        // phone: '+55 ' + values.cellphone,
        password: values.password,
        email: values.email,
        taxIdentification: values.taxIdentification,
        stateRegistration: values.stateRegistration,
        stateRegistrationExp: values.stateRegistrationExp,
        isNewsLetterSubscribed: values.newsletter.toString(),
      };

      setLoading(true);

      try {
        await createUser(API_URL, user);

        const newRecaptchaToken = await renewRecaptchaToken();

        const loginResponse = await userLogin(
          API_URL,
          values.email,
          values.password,
          newRecaptchaToken,
        );

        redirectHistory(localRedirect, loginResponse.access_token);
      } catch (e: any) {
        console.log('um erro ocorreu', e);

        setLoading(false);

        togglePopup();
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.name === 'newsletter' ||
      event.target.name === 'stateRegistrationExp'
    ) {
      if (
        event.target.name === 'stateRegistrationExp' &&
        event.target.checked
      ) {
        errors.stateRegistration = '';
      }

      setValues({
        ...values,
        [event.target.name]: event.target.checked,
      });
      return;
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputSetValue = (inputName: string, value: string) => {
    setValues({
      ...values,
      [inputName]: value,
    });
  };

  function setFieldError(field: string, error: string | null) {
    error = error === null ? '' : error;
    setErrors({
      ...errors,
      [field]: error,
    });
  }

  const handleFieldsValidate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldata = e.target.value;
    const error = validateFormFields(e.target.name, fieldata);
    const terror = error ? translate[error] : '';
    setFieldError(e.target.name, terror);

    if (error) {
      sendErrorToGa(
        'login',
        'registration legal',
        e.target.name + ': ' + error,
      );
    }
  };

  const validateFormFields = useCallback(
    (name: string, fieldata: string) => {
      if (name === 'name') {
        return nameValidator(fieldata);
      }
      if (name === 'cellphone') {
        return cellphoneValidator(fieldata);
      }
      if (name === 'taxIdentification') {
        return cnpjValidator(fieldata);
      }
      if (name === 'stateRegistration') {
        if (values.stateRegistrationExp === true) {
          return '';
        }
        return stateRegistrationValidator(fieldata);
      }
      if (name === 'email') {
        return emailValidator(fieldata);
      }
      if (name === 'password') {
        return passwordValidator(fieldata);
      }
      return '';
    },
    [values.stateRegistrationExp],
  );

  useMemo(() => {
    if (values === null) return setIsValid(false);
    for (const [key, value] of Object.entries(values)) {
      const error = validateFormFields(key, value);
      if (error.length > 0) {
        setIsValid(false);
        return;
      }
    }

    setIsValid(true);
  }, [values, validateFormFields]);

  return (
    <>
      <Loading visibled={loading} />
      <Popup
        PopupContent={AccountExists}
        isOpen={isPopupOpen}
        onClose={togglePopup}
      />
      <form className={styles.formNatural} autoComplete="off">
        <InputText
          placeholder={translate['login-registration-name']}
          name="name"
          type="text"
          value={values.name}
          error={errors.name.length > 0}
          textError={errors.name}
          required={true}
          onBlur={handleFieldsValidate}
          onChange={handleChange}
        />

        <InputMasked
          placeholder={translate['login-registration-cellphone']}
          name="cellphone"
          value={values.cellphone}
          mask="(99)9 9999-9999"
          inputMode="numeric"
          error={errors.cellphone.length > 0}
          textError={errors.cellphone}
          onChange={handleChange}
          onBlur={handleFieldsValidate}
        />

        <InputPasswordRules
          country="BR"
          inputPassValue={values.password}
          setInputPassValue={(value) => handleInputSetValue('password', value)}
          onBlur={handleFieldsValidate}
          autoComplete="new-password"
        />

        <InputText
          placeholder={translate['login-registration-email']}
          name="email"
          type="email"
          value={values.email}
          setValue={(value) => handleInputSetValue('email', value)}
          error={errors.email.length > 0}
          textError={errors.email}
          required={true}
          onBlur={handleFieldsValidate}
          onChange={handleChange}
        />

        <InputMasked
          placeholder={translate['login-registration-cnpj']}
          name="taxIdentification"
          value={values.taxIdentification}
          mask="99.999.999/9999-99"
          inputMode="numeric"
          error={errors.taxIdentification.length > 0}
          textError={errors.taxIdentification}
          onChange={handleChange}
          onBlur={handleFieldsValidate}
        />

        <div className={styles.stateRegistration}>
          <InputMasked
            placeholder={translate['login-registration-state-registration']}
            name="stateRegistration"
            value={values.stateRegistration}
            mask="999.999.999.999"
            inputMode="numeric"
            error={errors.stateRegistration.length > 0}
            textError={errors.stateRegistration}
            onChange={handleChange}
            onBlur={handleFieldsValidate}
          />
          <label className={styles.acceptNews}>
            <input
              type="checkbox"
              name="stateRegistrationExp"
              value={values.stateRegistrationExp.toString()}
              onChange={handleChange}
            />
            <span>
              {translate['login-registration-state-registration-exp']}
            </span>
          </label>
        </div>

        <div className={styles.acceptNews}>
          <label>
            <input
              type="checkbox"
              name="newsletter"
              checked={values.newsletter}
              value={values.newsletter.toString()}
              onChange={handleChange}
            />
            <span>{translate['login-registration-accept-newsletter']}</span>
          </label>
        </div>

        <div className={styles.acceptRegistration}>
          <span
            className={styles.acceptRegistration}
            dangerouslySetInnerHTML={{
              __html: translate['login-registration-accept-registration'],
            }}
          />
        </div>

        <Button
          id={styles.createAccount}
          text={translate['login-manual-button-create-account']}
          type="button"
          disabled={!isValid}
          onClick={handleCreateAccount}
        />

        <div className={styles.backLogin}>
          {translate['login-registration-have-account']}
          &nbsp;
          <Link to={'/'}>{translate['login-registration-click-enter']}</Link>
        </div>
      </form>
    </>
  );
};

export default CreateAccountBRLegal;
