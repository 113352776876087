/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef } from 'react';
import useReCaptcha from '../../hooks/useReCaptcha';
import { useConfig } from '../../services/ConfigProvider';

interface ReCaptchaProps {
  setToken: (token: string) => void;
  action: string;
  renewToken: () => void;
}

const ReCaptcha: React.FC<ReCaptchaProps> = ({
  setToken,
  action,
  renewToken,
}) => {
  const config = useConfig();
  const recaptchaPublicKey = config ? config.REACT_APP_RECAPTCHA : '';
  const { recaptchaReady } = useReCaptcha(recaptchaPublicKey);
  const recaptchaRef = useRef<HTMLDivElement>(null);

  const executeReCaptcha = useCallback(() => {
    if (recaptchaReady && window.grecaptcha) {
      window.grecaptcha.enterprise
        .execute(recaptchaPublicKey, { action })
        .then((newToken: string) => {
          setToken(newToken);
        })
        .catch((error: any) => {
          console.error('Erro ao executar reCAPTCHA:', error);
        });
    }
  }, [recaptchaReady, recaptchaPublicKey, action, setToken]);

  useEffect(() => {
    executeReCaptcha();
  }, [recaptchaReady, executeReCaptcha]);

  useEffect(() => {
    if (recaptchaReady) {
      renewToken();
    }
  }, [recaptchaReady, renewToken]);

  return <div ref={recaptchaRef} />;
};

export default ReCaptcha;
