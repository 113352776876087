/* eslint-disable  @typescript-eslint/no-explicit-any */
import cnpjValidation from '../../utils/validation/cnpjValidation';
import cpfValidation from '../../utils/validation/cpfValidation';
import emailValidation from '../../utils/validation/emailValidation';
import passwordValidation from '../../utils/validation/passwordValidation';
import { rutValidation } from '../../utils/validation/rutValidation';

const onlyNumbers = (text: string) => {
  return text.replace(/[^0-9]/g, '');
};

const betweenNumbers = (check: number, start: number, end: number): boolean => {
  return check >= start && check <= end;
};

const nameValidator = (text: string) => {
  if (text.trim().length == 0) {
    return 'login-registration-check-name';
  } else {
    return '';
  }
};

const fullnameValidator = (text: string) => {
  if (new RegExp(/[0-9]/).test(text)) {
    return 'login-registration-only-letters';
  } else if (text.trim().indexOf(' ') <= 0) {
    return 'login-registration-add-surname';
  } else {
    return '';
  }
};

const cellphoneValidator = (text: string) => {
  return '';
  return onlyNumbers(text).length != 11 ? 'login-registration-check-phone' : '';
};

const cpfValidator = (text: string) => {
  return cpfValidation(onlyNumbers(text))
    ? ''
    : 'login-registration-check-taxid';
};

const cnpjValidator = (text: string) => {
  return cnpjValidation(onlyNumbers(text))
    ? ''
    : 'login-registration-check-cnpj';
};

const rutValidator = (text: string) => {
  return rutValidation(onlyNumbers(text))
    ? ''
    : 'login-registration-check-taxid';
};

const stateRegistrationValidator = (text: string) => {
  return onlyNumbers(text).trim().length > 0
    ? ''
    : 'login-registration-check-state-registration';
};

const emailValidator = (text: string) => {
  return emailValidation(text) ? '' : 'login-registration-check-email';
};

const birthdayValidator = (text: string) => {
  const [day, month, year] = text.split('/');

  if (
    !betweenNumbers(parseInt(day), 1, 31) ||
    !betweenNumbers(parseInt(month), 1, 12) ||
    !betweenNumbers(parseInt(year), 1900, 3000)
  )
    return 'login-registration-check-birth';

  const birthday = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  const today = new Date();

  if (birthday.toString() === 'Invalid Date') {
    return 'login-registration-check-birth';
  }

  if (
    birthday.getDate() != parseInt(day) ||
    birthday.getMonth() + 1 != parseInt(month) ||
    birthday.getFullYear() != parseInt(year)
  ) {
    return 'login-registration-check-birth';
  }

  const years = Math.floor(
    (today.getTime() - birthday.getTime()) / (1000 * 60 * 60 * 24 * 365),
  );

  return years >= 18 ? '' : 'login-registration-over-eighteen';
};

const passwordValidator = (text: string) => {
  return passwordValidation(text) ? '' : 'login-registration-check-password';
};

export {
  nameValidator,
  fullnameValidator,
  cellphoneValidator,
  cpfValidator,
  cnpjValidator,
  emailValidator,
  birthdayValidator,
  passwordValidator,
  stateRegistrationValidator,
  rutValidator,
};
