import { ITranslationCountryType } from '../../utils/translation/types';
import styles from './styles.module.css';

interface SecurityProps {
  translate: ITranslationCountryType;
}

const Security = (props: SecurityProps) => {
  const { translate } = props;
  return (
    <div className={styles.security}>
      <b className={styles.seguranaECertificado}>
        {translate['footer-security-certificate']}
      </b>
      <img
        className={styles.image12Icon}
        loading="lazy"
        alt=""
        src="/image-12@2x.png"
      />
      <div className={styles.siteSeguroCom}>
        {translate['footer-secure-website']}
      </div>
      <div className={styles.homologadoPelaUsertrust}>
        {translate['footer-usertrust']}
      </div>
    </div>
  );
};

export default Security;
