import { configGetAliceHost, useConfig } from '../../services/ConfigProvider';
import styles from './styles.module.css';

const Brand = () => {
  const config = useConfig();
  const aliceUrl = configGetAliceHost(config);
  return (
    <div className={styles.brandNameContainer}>
      <a href={aliceUrl} className={styles.brandLink}>
        <img
          className={styles.dafitiBlackPositive}
          loading="lazy"
          alt=""
          src="/dafiti--black--positive.svg"
        />
      </a>
    </div>
  );
};

export default Brand;
