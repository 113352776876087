/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components';
import styles from './account-exists.styles.module.css';
import { useConfig } from '../../services/ConfigProvider';
import useTranslation from '../../utils/translation';
import {
  pushToDataLayer,
  pushPageViewDataLayer,
} from '../../services/googleTagManager';

interface Props {
  closePopupFunction?: () => void;
}

const AccountExists = (props: Props) => {
  const { closePopupFunction } = props;

  const config = useConfig();
  const COUNTRY = config?.REACT_APP_COUNTRY || 'BR';

  const translate = useTranslation(COUNTRY);

  const navigate = useNavigate();

  useEffect(() => {
    pushPageViewDataLayer('registration - already exists', 'visualizado');
  }, []);

  const handleSendRestorePassword = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    pushToDataLayer(
      'login',
      'registration - already exists',
      '',
      'Restore Password button',
    );
    navigate('/#forgotpassword');

    if (closePopupFunction != undefined) {
      closePopupFunction();
    }
  };

  const handleClosePopup = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    pushToDataLayer(
      'login',
      'registration - already exists',
      '',
      'Popup closed',
    );
    navigate('/');

    if (closePopupFunction != undefined) {
      closePopupFunction();
    }
  };

  return (
    <div className={styles.accountexists}>
      <h1>{translate['login-registration-account-exists-title']}</h1>

      <p
        dangerouslySetInnerHTML={{
          __html: translate['login-registration-account-exists-desc'],
        }}
      />
      <form className={styles.form}>
        <Button
          type="submit"
          text={translate['login-manual-button-submit-text']}
          onClick={(e) => handleClosePopup(e)}
        />
        <Button
          type="button"
          text={translate['login-registration-account-exists-restore-pass']}
          onClick={handleSendRestorePassword}
        />
      </form>
    </div>
  );
};

export default AccountExists;
