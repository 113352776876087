import { useConfig } from './ConfigProvider';
import { GoogleTagManagerProps } from './types';

declare global {
  interface Window {
    dataLayer: GoogleTagManagerProps[];
  }
}

export const pushToDataLayer = (
  type: string,
  method: string,
  userId: string,
  buyer: string,
) => {
  if (window && window.dataLayer) {
    let event = '';
    if (type === 'login') {
      event = 'login_ga4';
    } else {
      event = 'sign_up_ga4';
    }
    window.dataLayer.push({
      event: event,
      method: method,
      user_id: userId,
      buyer_type: buyer,
    });
  }
};

export const pushPageViewDataLayer = (page: string, event: string) => {
  // Verifica se o dataLayer está disponível
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'page_view_ga4',
      page_path: window.location.pathname,
      page_location: window.location.href,
      page_title: page,
      buyer_type: event,
    });
  } else {
    console.log('dataLayer is not defined');
  }
};

export const sendErrorToGa = (
  trigger: string,
  errorType: string,
  errorName: string,
) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'error_event_ga4',
      error_trigger_time: trigger,
      error_type: errorType,
      error_name: errorName,
    });
  }
};

export default () => {
  const config = useConfig();
  const gtmId = config ? config.REACT_APP_GOOGLE_TAG_MANAGER : '';

  const script = document.createElement('script');
  script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
  document.head.appendChild(script);
};
