import React from 'react';
import styles from './styles.module.css';
import { useConfig } from '../../services/ConfigProvider';
import useTranslation from '../../utils/translation';
import Payment from './Payment';
import SupportInfo from './SupportInfo';
import Security from './Security';
import AllRightsReserved from './AllRightsReserved';

const Footer = () => {
  const config = useConfig();
  const COUNTRY = (config && config.REACT_APP_COUNTRY) || 'BR';
  const translate = useTranslation(COUNTRY);

  const showPayment = false;

  return (
    <footer className={styles.footerBrazil}>
      <div className={styles.container}>
        {showPayment && <Payment />}
        <SupportInfo translate={translate} />
      </div>
      <Security translate={translate} />
      <AllRightsReserved translate={translate} />
    </footer>
  );
};

export default Footer;
