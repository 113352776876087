import { Link, useNavigate } from 'react-router-dom';
import { useConfig } from '../../services/ConfigProvider';
import useTranslation from '../../utils/translation';
import styles from './styles.module.css';
const Header = () => {
  const config = useConfig();
  const COUNTRY = (config && config.REACT_APP_COUNTRY) || 'BR';
  const translate = useTranslation(COUNTRY);

  const navigate = useNavigate();

  return (
    <header className={styles.main}>
      <Link
        to={'..'}
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
        id="back"
        className={styles.header}
      >
        <div className={styles.headerBack}>
          <img
            className={styles.interfaceBack}
            loading="lazy"
            alt=""
            src="/interface--back.svg"
          />
        </div>
        <div className={styles.buttonTertiary}>
          <div className={styles.content}>
            <div className={styles.button}>{translate['header-back']}</div>
            <div className={styles.divider} />
          </div>
        </div>
      </Link>
    </header>
  );
};

export default Header;
