import {
  TOKEN_POST,
  TOKEN_VALIDATE_POST,
  USER_GET,
  SOCIAL_ASSOCIATION_POST,
  FORGOT_PASSWORD_POST,
  VALIDATE_RESTORE_PASSWORD_KEY,
  RESTORE_PASSWORD,
  CREATE_USER,
} from './Login';

export default {
  TOKEN_POST,
  USER_GET,
  TOKEN_VALIDATE_POST,
  SOCIAL_ASSOCIATION_POST,
  FORGOT_PASSWORD_POST,
  VALIDATE_RESTORE_PASSWORD_KEY,
  RESTORE_PASSWORD,
  CREATE_USER,
};
