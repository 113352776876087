import styles from './styles.module.css';

const Error = ({ text }: ErrorType) => {
  return (
    <div className={styles.error}>
      <div className={styles['interface-info']}>
        <span className={styles['icon']}>!</span>
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

interface ErrorType {
  text: string;
}

export default Error;
