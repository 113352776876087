/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Loading } from '../../components';
import { useConfig } from '../../services/ConfigProvider';
import useTranslation from '../../utils/translation';
import styles from './styles.module.css';
import {
  resetPassword,
  userLogin,
  validateRestorePasswordKey,
} from '../../services/Login';
import redirectHistory from '../../utils/redirect';
import {
  pushPageViewDataLayer,
  pushToDataLayer,
  sendErrorToGa,
} from '../../services/googleTagManager';
import RecaptchaContext from '../../hooks/RecaptchaContext';
import useReCaptcha from '../../hooks/useReCaptcha';
import InputPasswordRules from '../../components/InputPasswordRules';
import passwordValidation from '../../utils/validation/passwordValidation';

const RestorePassword: FunctionComponent = () => {
  const { restoreKey } = useParams();

  const navigate = useNavigate();

  const config = useConfig();
  const COUNTRY = (config && config.REACT_APP_COUNTRY) || 'BR';
  const API_URL = config?.REACT_APP_LOGIN_ADAPTER_URL || '';
  const recaptchaPublicKey = config?.REACT_APP_RECAPTCHA || '';
  const translate = useTranslation(COUNTRY);

  const [inputPassValue, setInputPassValue] = useState('');
  const [isValid, setIsValid] = useState(false);

  const [restoreIdentity, setRestoreIdentity] = useState('');

  const [loading, setLoading] = useState(true);

  const [recaptchaToken, setRecaptchaToken] = useState('');
  const { recaptchaReady } = useReCaptcha(recaptchaPublicKey);
  const recaptcha = useContext(RecaptchaContext);

  const localRedirect = config?.REACT_APP_ALICE_HOST
    ? `${config.REACT_APP_ALICE_HOST}`
    : '';

  useEffect(() => {
    pushPageViewDataLayer('login - recupera senha', 'visualizado');
  }, []);

  useEffect(() => {
    const renewRecaptchaToken = async () => {
      if (recaptchaReady) {
        const recaptchaToken =
          await recaptcha.executeReCaptcha(recaptchaPublicKey);
        setRecaptchaToken(recaptchaToken);
      }
    };

    renewRecaptchaToken();
  }, [recaptcha, recaptchaPublicKey, recaptchaReady, setRecaptchaToken]);

  const maskText = (text: string) => {
    return (
      text.substring(0, 2) +
      '***' +
      text.substring(text.length - 2, text.length)
    );
  };

  useEffect(() => {
    setLoading(true);
    if (restoreKey === undefined || restoreKey == null) {
      navigate('/');
      return;
    }

    try {
      validateRestorePasswordKey(API_URL, restoreKey)
        .then((userIdentity) => {
          if (userIdentity.email === undefined) {
            navigate('/');
            return;
          }
          setLoading(false);
          if (COUNTRY === 'BR') {
            setRestoreIdentity(userIdentity.tax_identification);
          } else {
            setRestoreIdentity(userIdentity.email);
          }
          restoreIdentity !== '' &&
            pushToDataLayer(
              'login',
              'recupera senha',
              maskText(restoreIdentity),
              'token reconhecido',
            );
        })
        .catch(() => {
          sendErrorToGa(
            'login',
            'recupera senha',
            'erro identificando usuário',
          );
          window.location.href = '/';
          return;
        });
    } catch (e: any) {
      sendErrorToGa('login', 'recupera senha', 'token inválido');
      setLoading(true);

      window.location.href = '/';
      return;
    }
  }, [restoreKey, restoreIdentity, API_URL, COUNTRY, navigate]);

  useMemo(
    () => setIsValid(passwordValidation(inputPassValue)),
    [inputPassValue],
  );

  const handlePassBlur = () => {
    const isValid = passwordValidation(inputPassValue);
    setIsValid(isValid);
    if (!isValid) {
      sendErrorToGa('login', 'recupera senha', 'senha inválida');
    }
  };

  const handleResetPassword = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    if (restoreKey === undefined) {
      sendErrorToGa('login', 'recupera senha', 'token inválido');

      window.location.href = '/';
      return;
    }

    setLoading(true);

    try {
      await resetPassword(API_URL, restoreKey, restoreIdentity, inputPassValue);
      const loginResponse = await userLogin(
        API_URL,
        restoreIdentity,
        inputPassValue,
        recaptchaToken,
      );

      pushToDataLayer(
        'login',
        'recupera senha',
        maskText(restoreIdentity),
        'senha recuperada com sucesso',
      );
      redirectHistory(`${localRedirect}`, loginResponse.access_token);
    } catch (e: any) {
      setRecaptchaToken('');
      setLoading(false);
      sendErrorToGa(
        'login',
        'recupera senha',
        'não foi possível restaurar a senha',
      );
    }
  };

  return (
    <>
      {loading && <Loading visibled={loading} />}
      <div className={styles.contentBox + ' ' + styles.resetPass}>
        <h1 className={styles.sectionTitle}>
          {translate['reset-pass-new-pass']}
        </h1>

        <form className={styles.loginFormFields} noValidate>
          <InputPasswordRules
            country={COUNTRY}
            inputPassValue={inputPassValue}
            setInputPassValue={setInputPassValue}
            onBlur={handlePassBlur}
            autoComplete="new-password"
          />

          <RecaptchaContext.Provider
            value={{
              executeReCaptcha: recaptcha.executeReCaptcha,
              recaptchaToken,
            }}
          />
          <Button
            onClick={handleResetPassword}
            text={translate['reset-pass-save-pass']}
            type="submit"
            disabled={!isValid}
          />
        </form>
      </div>
    </>
  );
};

export default RestorePassword;
