/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  InputText,
  LinkButton,
  Loading,
  Notification,
  ReCaptcha,
} from '../../components';
import styles from './styles.module.css';
import validation from '../../utils/validation';
import * as configProvider from '../../services/ConfigProvider';
import { userLogin, associateLogin } from '../../services/Login';
import useTranslation from '../../utils/translation';
import {
  pushToDataLayer,
  sendErrorToGa,
} from '../../services/googleTagManager';
import redirectHistory from '../../utils/redirect';
import Popup from '../../components/Popup';
import ForgotPass from '../ForgotPassword';
import checkAutofill from '../../utils/helper/check-autofill';

function LoginManual() {
  const navigate = useNavigate();
  const config = configProvider.useConfig();

  const API_URL = configProvider.configGetLoginAdapterUrl(config);
  const COUNTRY = configProvider.configGetCountry(config);
  const localRedirect = configProvider.configGetAliceHost(config);

  const [inputTextValue, setInputTextValue] = useState('');
  const [inputPassValue, setInputPassValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [textError, setTextError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitleMessage, setErrorTitleMessage] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [autoFill, setAutoFill] = useState(false);

  const translate = useTranslation(COUNTRY);

  const urlHash = window.location.hash.replace('#', '');

  useEffect(() => {
    setErrorMessage(translate['error-message-submit-manual']);
    setErrorTitleMessage(translate['error-title-submit-manual']);

    if (urlHash.indexOf('forgotpassword') > -1) {
      setIsPopupOpen(true);
    }
  }, [setErrorTitleMessage, setErrorMessage, translate, urlHash]);

  // Callback function to handle recaptcha token regeneration
  const executeReCaptcha = useCallback(() => {
    if (window.grecaptcha) {
      const recaptchaPublicKey = config?.REACT_APP_RECAPTCHA || '';
      window.grecaptcha.enterprise
        .execute(recaptchaPublicKey, { action: 'login' })
        .then((newToken: string) => {
          setRecaptchaToken(newToken);
        })
        .catch((error: any) => {
          console.error('Erro ao executar reCAPTCHA:', error);
        });
    }
  }, [config]);

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputTextValue(e.target.value);
  };

  const handlePassChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputPassValue(e.target.value);
  };

  const handleFieldsOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case 'password':
        if (validatePassword(e.target.value)) {
          setPasswordError('');
        } else {
          setPasswordError(translate['error-verify-pass']);
          sendErrorToGa('login', 'falha no login', 'password: text not valid');
          setIsValid(false);
        }
        break;

      case 'email':
        if (validateLogin(e.target.value)) {
          setTextError('');
          setError(false);
        } else {
          setTextError(translate['error-verify-email']);
          setError(true);
          setIsValid(false);

          sendErrorToGa('login', 'falha no login', 'email: text not valid');
        }
        break;
    }
  };

  const validateLogin = useCallback(
    (text: string) => {
      if (validation.emailValidation(text)) return true;
      if (COUNTRY === 'BR' && validation.cpfValidation(text)) return true;
      return false;
    },
    [COUNTRY],
  );

  const validatePassword = (text: string) => {
    if (text.length > 1) return true;

    return false;
  };

  useMemo(
    () =>
      setIsValid(
        recaptchaToken != '' &&
          (autoFill ||
            (validateLogin(inputTextValue) &&
              validatePassword(inputPassValue))),
      ),
    [inputTextValue, inputPassValue, recaptchaToken, autoFill, validateLogin],
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !validateLogin(inputTextValue) ||
      !validatePassword(inputPassValue) ||
      recaptchaToken == ''
    ) {
      setIsValid(false);
      return;
    }

    if (isValid) {
      try {
        setLoading(true);

        const loginResponse = await userLogin(
          API_URL,
          inputTextValue,
          inputPassValue,
          recaptchaToken,
        );

        const token_social = window.localStorage.getItem('token_social');

        if (token_social !== undefined && token_social !== null) {
          try {
            await associateLogin(
              API_URL,
              loginResponse.access_token,
              token_social,
            );
            window.localStorage.removeItem('token_social');
          } catch (err: any) {
            setErrorTitleMessage(
              translate['login-social-associate-error-title'],
            );
            setErrorMessage(translate['login-social-associate-error-desc']);

            sendErrorToGa(
              'login',
              'falha no login',
              'falha ao associar login social',
            );
            setError(true);

            setFormError(true);

            window.localStorage.removeItem('token_social');

            return false;
          }

          pushToDataLayer('login', 'social', '', 'social association success');
        }
        redirectHistory(`${localRedirect}`, loginResponse.access_token);
      } catch (e: any) {
        sendErrorToGa('login', 'falha no login', 'Erro  ao logar');
        console.log(e);
        setError(true);
        setPasswordError(translate['error-verify-pass']);
        setTextError(translate['error-verify-email']);
        setFormError(true);
      } finally {
        setRecaptchaToken('');
        executeReCaptcha(); // Regenera o token após o envio
        setLoading(false);
      }
    }
  };

  const handleCloseNotification = () => {
    setFormError(false);
  };

  const handleCreateAccount = () => {
    const params = new URLSearchParams(window.location.search);
    const urlRedirect = params.get('returnUrl');

    let createUrl = '/create';
    if (urlRedirect != null && urlRedirect != '') {
      createUrl = createUrl + '?returnUrl=' + encodeURIComponent(urlRedirect);
    }
    navigate(createUrl);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    let cont = 0;
    const interval = setInterval(() => {
      const found = checkAutofill('styles_active__OmSYD');
      if (found === true) {
        clearInterval(interval);
        setAutoFill(true);
      }
      if (cont >= 60) {
        clearInterval(interval);
      }
      cont = cont + 1;
    }, 1000);
  }, []);

  return (
    <>
      <Loading visibled={loading} />

      <div className={styles.emailLogin}>
        <h1 className={styles.entreOuCadastreSe}>
          {translate['title-manual-registration']}
        </h1>
        <div className={styles.emailLoginForm}>
          <form
            className={styles.loginFormFields}
            onSubmit={handleSubmit}
            noValidate
          >
            {formError && (
              <Notification
                status="error"
                title={errorTitleMessage}
                message={errorMessage}
                showLink={false}
                onclick={handleCloseNotification}
              />
            )}

            <InputText
              required
              placeholder={translate['login-manual-label-login']}
              name="email"
              type="email"
              value={inputTextValue}
              setValue={setInputTextValue}
              textError={textError}
              error={error}
              onChange={handleTextChange}
              onBlur={handleFieldsOnBlur}
            />

            <InputText
              required
              placeholder={translate['login-manual-label-password']}
              name="password"
              type="password"
              value={inputPassValue}
              error={passwordError.length > 0}
              textError={passwordError}
              onBlur={handleFieldsOnBlur}
              onChange={handlePassChange}
            />

            <div className={styles.loginButtons}>
              <Button
                text={translate['login-manual-button-submit-text']}
                type="submit"
                disabled={!isValid}
              />

              <LinkButton
                text={translate['login-manual-button-forgot-password']}
                onclick={togglePopup}
              />
            </div>

            <Button
              id={styles.createAccount}
              text={translate['login-manual-button-create-account']}
              type="button"
              onClick={handleCreateAccount}
            />

            <ReCaptcha
              setToken={setRecaptchaToken}
              action="submit"
              renewToken={executeReCaptcha}
            />

            <Popup
              PopupContent={ForgotPass}
              isOpen={isPopupOpen}
              onClose={togglePopup}
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginManual;
