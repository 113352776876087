import Cookies from 'js-cookie';
import { v1 as uuidv4 } from 'uuid';

const getHeader = () => {
  const country = window.localStorage.getItem('country')?.toLocaleLowerCase();

  const deviceId = Cookies.get('_ga')
    ? Cookies.get('_ga')
    : uuidv4().toUpperCase();

  return {
    'Content-Type': 'application/json',
    'X-Store': 'dafiti-' + country,
    'x-Os': 'web',
    'X-Device-Id': `${deviceId}`,
    'X-Origin-Url-Request': window.location.href,
  };
};

export function USER_GET(API_URL: string, token: string) {
  return {
    url: API_URL + '/api/v1/customer/info',
    options: {
      method: 'GET',
      headers: getHeader(),
      body: JSON.stringify({ access_token: token }),
    },
  };
}

export function TOKEN_POST(API_URL: string, body: object) {
  return {
    url: API_URL + '/api/v1/customer/login',
    options: {
      method: 'POST',
      headers: getHeader(),
      body: JSON.stringify(body),
    },
  };
}

export function SOCIAL_ASSOCIATION_POST(API_URL: string, body: object) {
  return {
    url: API_URL + '/api/v1/customer/social/association',
    options: {
      method: 'POST',
      headers: getHeader(),
      body: JSON.stringify(body),
    },
  };
}

export function TOKEN_VALIDATE_POST(API_URL: string, token: string) {
  return {
    url: API_URL + '/v1/token/validate',
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

export function FORGOT_PASSWORD_POST(API_URL: string, body: object) {
  return {
    url: API_URL + '/api/v1/customer/forgot-password',
    options: {
      method: 'POST',
      headers: getHeader(),
      body: JSON.stringify(body),
    },
  };
}

export function VALIDATE_RESTORE_PASSWORD_KEY(API_URL: string, body: object) {
  return {
    url: API_URL + '/api/v1/customer/validate-restore-password-key',
    options: {
      method: 'POST',
      headers: getHeader(),
      body: JSON.stringify(body),
    },
  };
}

export function RESTORE_PASSWORD(API_URL: string, body: object) {
  return {
    url: API_URL + '/api/v1/customer/reset-password',
    options: {
      method: 'POST',
      headers: getHeader(),
      body: JSON.stringify(body),
    },
  };
}

export function CREATE_USER(API_URL: string, body: object) {
  return {
    url: API_URL + '/api/v1/customer/create',
    options: {
      method: 'POST',
      headers: getHeader(),
      body: JSON.stringify(body),
    },
  };
}
