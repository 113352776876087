import { useEffect } from 'react';

const AppleLoginSDK = (clientId: string) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    script.async = true;
    script.onload = () => {
      if (window.AppleID) {
        window.AppleID.auth.init({
          clientId: clientId,
          redirectURI: window.location.origin + '/',
          scope: 'name email',
          state: 'state',
          usePopup: true,
        });
      }
    };
    document.body.appendChild(script);
  }, [clientId]);
};

export default AppleLoginSDK;
