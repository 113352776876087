/* eslint-disable  @typescript-eslint/no-explicit-any */
import { createContext } from 'react';

async function googleRecaptchaInitialize(recaptchaPublicKey: string) {
  if (window.grecaptcha) {
    try {
      const newToken = await window.grecaptcha.enterprise.execute(
        recaptchaPublicKey,
        { action: 'submit' },
      );

      return newToken;
    } catch (error: any) {
      console.error('Erro ao executar reCAPTCHA:', error);
      return 'Erro ao executar reCAPTCHA';
    }
  }
  return 'recaptcha not available';
}

const RecaptchaContext = createContext({
  executeReCaptcha: googleRecaptchaInitialize,
  recaptchaToken: '',
});

export default RecaptchaContext;
