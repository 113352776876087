import { ChangeEvent, useMemo, useState } from 'react';
import useTranslation from '../../utils/translation';
import passwordValidation, {
  checkHas8PlusChars,
  checkHasNoSequencialChars,
  checkHasSpecial,
  checkHasUppercase,
} from '../../utils/validation/passwordValidation';
import InputText from '../InputText';
import styles from './styles.module.css';

interface InputPasswordRulesInterface {
  country: string;
  setInputPassValue: (text: string) => void;
  inputPassValue: string;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  difficult?: 'easy' | 'hard';
}

const InputPasswordRules = ({
  setInputPassValue,
  inputPassValue,
  country,
  onBlur,
  autoComplete = '',
  difficult = 'easy',
}: InputPasswordRulesInterface) => {
  const translate = useTranslation(country);

  const [passRuleLess8, setPassRuleLess8] = useState(false);
  const [passRuleUpper1, setPassRuleUpper1] = useState(false);
  const [passRuleSpecial, setPassRuleSpecial] = useState(false);
  const [passRuleSequencial, setPassRuleSequencial] = useState(false);

  const [failpass, setFailpass] = useState(false);
  const [error, setError] = useState(false);

  const [showRules, setShowRules] = useState(false);

  const handlePassChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowRules(true);
    const text = e.target.value;
    setInputPassValue(text);

    setPassRuleLess8(checkHas8PlusChars(text));
    setPassRuleUpper1(checkHasUppercase(text));
    setPassRuleSpecial(checkHasSpecial(text));
    if (difficult == 'hard') {
      setPassRuleSequencial(checkHasNoSequencialChars(text));
    }
  };

  const isValid = useMemo(() => {
    return passwordValidation(inputPassValue, difficult);
  }, [inputPassValue, difficult]);

  const handlePassBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setFailpass(!isValid);
    setError(!isValid);
    setShowRules(!isValid);

    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <>
      <InputText
        required
        placeholder={translate['pass-validator-label-password']}
        name="password"
        type="password"
        error={error}
        textError={translate['pass-validator-password-fail']}
        value={inputPassValue}
        onChange={handlePassChange}
        onBlur={handlePassBlur}
        autoComplete={autoComplete}
      />

      {showRules && (
        <div className={styles.passRules}>
          {translate['pass-rule-title']}
          <div
            className={`${styles.checkStatus} ${passRuleLess8 ? styles.valid : failpass ? styles.error : ''}`}
          >
            {translate['pass-rule-8-chars']}
          </div>
          <div
            className={`${styles.checkStatus} ${passRuleUpper1 ? styles.valid : failpass ? styles.error : ''}`}
          >
            {translate['pass-rule-1-uppercase']}
          </div>
          <div
            className={`${styles.checkStatus} ${passRuleSpecial ? styles.valid : failpass ? styles.error : ''}`}
          >
            {translate['pass-rule-1-special-char']}
          </div>
          {difficult === 'hard' && (
            <div
              className={`${styles.checkStatus} ${passRuleSequencial ? styles.valid : failpass ? styles.error : ''}`}
            >
              {translate['pass-rule-1-sequencial-char']}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InputPasswordRules;
