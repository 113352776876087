import { ITranslationCountryType } from '../../utils/translation/types';
import styles from './styles.module.css';

interface AllRightsReservedProps {
  translate: ITranslationCountryType;
}

const AllRightsReserved = (props: AllRightsReservedProps) => {
  const { translate } = props;
  return (
    <div className={styles.AllRightsReserved}>
      <img
        className={styles.gfgHorizontalPositive}
        loading="lazy"
        alt=""
        src="/gfg--horizontal--positive.svg"
      />
      <div className={styles.copyright}>
        <div>{translate['footer-all-rights-reserved']}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: translate['footer-policy-privacy'],
          }}
        />
      </div>
    </div>
  );
};

export default AllRightsReserved;
