/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, InputText, Loading } from '../../components';
import InputMasked from '../../components/InputMasked';
import InputPasswordRules from '../../components/InputPasswordRules';
import Popup from '../../components/Popup';
import RecaptchaContext from '../../hooks/RecaptchaContext';
import * as configProvider from '../../services/ConfigProvider';
import {
  pushPageViewDataLayer,
  sendErrorToGa,
} from '../../services/googleTagManager';
import { createUser, userLogin } from '../../services/Login';
import redirectHistory from '../../utils/redirect';
import useTranslation from '../../utils/translation';
import AccountExists from './AccountExists';
import {
  birthdayValidator,
  cellphoneValidator,
  emailValidator,
  fullnameValidator,
  passwordValidator,
  rutValidator,
} from './FieldsValidator';
import styles from './styles.module.css';
import stylesLogin from '../Login/styles.module.css';
import checkAutofill from '../../utils/helper/check-autofill';

interface CustomerNatural {
  fullname: string;
  cellphone: string;
  taxid: string;
  email: string;
  birthday: string;
  password: string;
  preferences: string;
  newsletter: boolean;
  accept: string;
}

const CustomerNaturalDefault = {
  fullname: '',
  cellphone: '',
  taxid: '',
  email: '',
  birthday: '',
  password: '',
  preferences: '',
  newsletter: true,
  accept: 'false',
};

const CreateAccountCL = () => {
  const config = configProvider.useConfig();

  const API_URL = configProvider.configGetLoginAdapterUrl(config);
  const COUNTRY = configProvider.configGetCountry(config);
  const localRedirect = configProvider.configGetAliceHost(config);
  const recaptchaPublicKey = configProvider.configGetRecaptchaKey(config);

  const [values, setValues] = useState<CustomerNatural>(CustomerNaturalDefault);
  const [errors, setErrors] = useState<CustomerNatural>(CustomerNaturalDefault);
  const [isValid, setIsValid] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const translate = useTranslation(COUNTRY);

  const recaptcha = useContext(RecaptchaContext);

  const renewRecaptchaToken = async () => {
    const token = await recaptcha.executeReCaptcha(recaptchaPublicKey);
    return token;
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    pushPageViewDataLayer('login - create', 'Registration CL');

    setTimeout(() => {
      checkAutofill('styles_active__OmSYD');
    }, 1000);
  }, []);

  const handleCreateAccount = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    let isOk = true;
    for (const [key, value] of Object.entries(values)) {
      if (key != '' && validateFormFields(key, value)) {
        isOk = false;
      }
    }

    if (isOk) {
      const [day, month, year] = values.birthday.split('/');
      const user = {
        lastName: values.fullname.split(' ').pop()?.toString() || '',
        firstName: values.fullname.substring(
          0,
          values.fullname.lastIndexOf(' '),
        ),
        birthday: year + '-' + month + '-' + day,
        email: values.email,
        password: values.password,
        identification: values.taxid,
        // phone: values.cellphone,
        isNewsLetterSubscribed: values.newsletter.toString(),
        gender:
          values.preferences === translate['login-registration-female-style']
            ? 'female'
            : 'male',
      };
      setLoading(true);
      try {
        await createUser(API_URL, user);

        const newRecaptchaToken = await renewRecaptchaToken();

        const loginResponse = await userLogin(
          API_URL,
          values.email,
          values.password,
          newRecaptchaToken,
        );

        redirectHistory(localRedirect, loginResponse.access_token);
      } catch (e: any) {
        console.log('um erro ocorreu', e);

        setLoading(false);

        togglePopup();
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleInputSetValue = (inputName: string, value: string) => {
    setValues({
      ...values,
      [inputName]: value,
    });
  };

  function setFieldError(field: string, error: string | null) {
    error = error === null ? '' : error;
    setErrors({
      ...errors,
      [field]: error,
    });
  }

  const handleFieldsValidate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldata = e.target.value;

    const error = validateFormFields(e.target.name, fieldata);
    const terror = error ? translate[error] : '';
    setFieldError(e.target.name, terror);

    if (error) {
      sendErrorToGa('login', 'registration', e.target.name + ': ' + error);
    }
  };

  const validateFormFields = useCallback((name: string, fieldata: string) => {
    if (name === 'fullname') {
      return fullnameValidator(fieldata);
    }
    if (name === 'cellphone') {
      return cellphoneValidator(fieldata);
    }
    if (name === 'taxid') {
      return rutValidator(fieldata);
    }
    if (name === 'email') {
      return emailValidator(fieldata);
    }
    if (name === 'birthday') {
      return birthdayValidator(fieldata);
    }
    if (name === 'password') {
      return passwordValidator(fieldata);
    }
    if (name === 'preferences' && fieldata.length == 0) {
      return 'login-registration-check-preferences';
    }
    if (name === 'accept' && fieldata.toString() == 'false') {
      return 'login-registration-please-accept';
    }
    return '';
  }, []);

  useMemo(() => {
    if (values === null) return setIsValid(false);
    for (const [key, value] of Object.entries(values)) {
      const error = validateFormFields(key, value);
      if (error.length > 0) {
        setIsValid(false);
        return;
      }
    }

    setIsValid(true);
  }, [values, validateFormFields]);

  return (
    <>
      <Loading visibled={loading} />

      <div className={styles.registration}>
        <h1 className={stylesLogin.sectionTitle}>
          {translate['title-manual-registration']}
        </h1>

        <Popup
          PopupContent={AccountExists}
          isOpen={isPopupOpen}
          onClose={togglePopup}
        />
        <form className={styles.formNatural} autoComplete="off">
          <InputText
            placeholder={translate['login-registration-fullname']}
            name="fullname"
            type="text"
            value={values.fullname}
            error={errors.fullname.length > 0}
            textError={errors.fullname}
            required={true}
            onBlur={handleFieldsValidate}
            onChange={handleChange}
          />

          <InputMasked
            placeholder={translate['login-registration-cellphone']}
            name="cellphone"
            value={values.cellphone}
            inputMode="numeric"
            mask="999999-99999"
            error={errors.cellphone.length > 0}
            textError={errors.cellphone}
            onChange={handleChange}
            onBlur={handleFieldsValidate}
          />

          <InputText
            placeholder={translate['login-registration-rut']}
            name="taxid"
            type="text"
            value={values.taxid}
            error={errors.taxid.length > 0}
            textError={errors.taxid}
            required={true}
            onBlur={handleFieldsValidate}
            onChange={handleChange}
          />

          <InputText
            placeholder={translate['login-registration-email']}
            name="email"
            type="email"
            value={values.email}
            setValue={(value) => handleInputSetValue('email', value)}
            error={errors.email.length > 0}
            textError={errors.email}
            required={true}
            onBlur={handleFieldsValidate}
            onChange={handleChange}
          />

          <InputMasked
            placeholder={translate['login-registration-birth']}
            name="birthday"
            value={values.birthday}
            inputMode="numeric"
            mask="99/99/9999"
            error={errors.birthday.length > 0}
            textError={errors.birthday}
            onChange={handleChange}
            onBlur={handleFieldsValidate}
          />

          <InputPasswordRules
            country={COUNTRY}
            inputPassValue={values.password}
            setInputPassValue={(value) =>
              handleInputSetValue('password', value)
            }
            onBlur={handleFieldsValidate}
          />

          <InputText
            placeholder={translate['login-registration-store-pref']}
            name="preferences"
            type="select"
            value={values.preferences}
            error={errors.preferences.length > 0}
            textError={errors.preferences}
            required={true}
            setValue={(value) => handleInputSetValue('preferences', value)}
            onChange={handleChange}
            onBlur={handleFieldsValidate}
            items={[
              translate['login-registration-female-style'],
              translate['login-registration-male-style'],
            ]}
          />

          <div className={styles.acceptNews}>
            <label>
              <input
                type="checkbox"
                name="newsletter"
                checked={values.newsletter}
                value={values.newsletter.toString()}
                onChange={handleChange}
                onBlur={handleFieldsValidate}
              />
              <span>{translate['login-registration-accept-newsletter']}</span>
            </label>
          </div>

          <div className={styles.acceptNews}>
            <div>
              <input
                type="checkbox"
                name="accept"
                value={values.accept.toString()}
                onChange={handleChange}
                onBlur={handleFieldsValidate}
              />
              <span
                className={styles.acceptNewsLinked}
                dangerouslySetInnerHTML={{
                  __html: translate['login-registration-accept-registration'],
                }}
              />
            </div>
          </div>

          <Button
            id={styles.createAccount}
            text={translate['login-manual-button-create-account']}
            type="button"
            disabled={!isValid}
            onClick={handleCreateAccount}
          />

          <div className={styles.backLogin}>
            {translate['login-registration-have-account']}
            &nbsp;
            <Link to={'/'}>{translate['login-registration-click-enter']}</Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateAccountCL;
