import { useEffect, useState } from 'react';

const useReCaptcha = (publicKey: string) => {
  const [recaptchaReady, setRecaptchaReady] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${publicKey}`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      if (window.grecaptcha) {
        window.grecaptcha.enterprise.ready(() => {
          setRecaptchaReady(true);
          window.grecaptcha.enterprise
            .execute(publicKey, { action: 'submit' })
            .then((newToken: string) => {
              setToken(newToken);
            });
        });
      }
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [publicKey]);

  return { recaptchaReady, token };
};

export default useReCaptcha;
