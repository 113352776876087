import { ITranslationCountryType } from '../../utils/translation/types';
import styles from './styles.module.css';

interface SupportInfoProps {
  translate: ITranslationCountryType;
}

const SupportInfo = (props: SupportInfoProps) => {
  const { translate } = props;

  return (
    <div className={styles.supportInfo}>
      <div className={styles.footerLinks}>
        <div className={styles.supportLink}>
          <b className={styles.ajudaESuporte}>
            {translate['footer-help-support']}
          </b>
        </div>
        <div className={styles.policyLinks}>
          <div className={styles.polticaDePrivacidade}>
            {translate['footer-policy-delivery-time']}
          </div>
          <div className={styles.polticaDeTrocas}>
            {translate['footer-policy-exchange-refund']}
          </div>
        </div>
      </div>

      <img
        className={styles.gfgHorizontalPositive}
        loading="lazy"
        alt=""
        src="/gfg--horizontal--positive.svg"
      />

      <div className={styles.contactInfo}>
        <b className={styles.centralDeAtendimento}>
          {translate['footer-call-center']}
        </b>
        <div className={styles.atendimentoCallContainer}>
          <span className={styles.atendimento}>
            <span>{translate['footer-service']}</span>
          </span>
          <span className={styles.span}>
            <span className={styles.span1}>{` `}</span>
            <b>{translate['footer-service-number']}</b>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SupportInfo;
