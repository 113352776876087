import { ChangeEvent, useState } from 'react';
import styles from './styles.module.css';
import Error from '../Error';
import { ReactComponent as InterfaceEye } from './interface--eye.svg';
import emailValidation from '../../utils/validation/emailValidation';

const InputText = ({
  placeholder,
  value,
  setValue,
  type = 'text',
  name = '',
  required = false,
  error = false,
  textError = '',
  items = [],
  onChange,
  onBlur,
}: InputTextProps) => {
  const [inputTextValue, setInputTextValue] = useState(value);
  const [hints, setHints] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [inputType, setInputType] = useState(type);
  const [openEye, setOpenEye] = useState(styles.interfaceEye);
  const [showSelectItems, setShowSelectItems] = useState(false);
  const [inputFielded, setInputFielded] = useState(0);

  const inputId =
    styles.inputText.replace('styles_inputText', 'inputId') +
    '_' +
    name.replaceAll(' ', '');
  const containerId = 'c__' + name.replaceAll(' ', '');

  const emailProviders = [
    '@gmail.com',
    '@yahoo.com',
    '@hotmail.com',
    '@outlook.com',
    '@icloud.com',
  ];

  const showEmailProviders = () => {
    setHints(true);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setInputTextValue(text);
    if (text !== '') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }

    if (inputFielded === 0 && text.length > 1 && emailValidation(text)) {
      setInputFielded(text.length);
    } else if (text.indexOf('@') > -1) {
      showEmailProviders();
    } else {
      setHints(false);
    }

    if (onChange) {
      onChange(e);
    }
  };

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setHints(false);
      setShowSelectItems(false);
      if (onBlur) {
        onBlur(e);
      }
    }, 300);
  };

  const handleOnClickHint = (element: HTMLDivElement) => {
    setIsActive(true);
    setShowSelectItems(false);
    setInputTextValue(element.innerHTML);
    setHints(false);
    if (setValue) setValue(element.innerHTML);
  };

  const handleEyeClicked = () => {
    if (inputType === 'password') {
      setOpenEye(styles.interfaceEyeOpen);
      setInputType('text');
    } else {
      setInputType('password');
      setOpenEye(styles.interfaceEye);
    }
  };

  const handleShowItems = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const element = document.getElementById(inputId);
    if (element) {
      element.focus();
    }
  };

  const handleOnFocus = () => {
    if (type === 'select') {
      setShowSelectItems(true);
    }
  };

  const readonly = type === 'select' ? true : false;
  const labelCursor =
    type === 'select' ? styles.labelPointer : styles.labelAuto;

  let cid = '';
  if (styles[containerId]) {
    cid = ' ' + styles[containerId];
  }

  return (
    <div className={styles.inputTextContent + cid}>
      <div
        className={
          styles.inputText +
          ' ' +
          (error ? styles.error : '') +
          (isActive ? ' ' + styles.active : '') +
          (hints ? ' ' + styles.inputHints : '')
        }
      >
        <input
          className={styles.label}
          name={name}
          id={inputId}
          type={inputType}
          value={inputTextValue}
          onChange={handleOnChange}
          required={required}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          readOnly={readonly}
        />
        {type === 'password' && (
          <div className={styles.icon}>
            <InterfaceEye className={openEye} onClick={handleEyeClicked} />
          </div>
        )}
        {type === 'email' && hints && (
          <div className={styles.hints}>
            <div className={styles['hints-content']}>
              {emailProviders &&
                emailProviders.map((provider, idx) => {
                  return (
                    <div
                      role="presentation"
                      className={styles.hint}
                      key={idx}
                      onClick={(e) => handleOnClickHint(e.currentTarget)}
                      onKeyUp={(e) => handleOnClickHint(e.currentTarget)}
                    >
                      {inputTextValue.split('@')[0] + provider}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {type === 'select' && (
          <>
            <div className={styles.icon}>
              <button
                className={
                  styles.selectArrow +
                  ' ' +
                  (showSelectItems ? styles.down : '')
                }
                onClick={(e) => handleShowItems(e)}
              />
            </div>
            {showSelectItems && (
              <div className={styles.hints}>
                <div className={styles['hints-content']}>
                  {items &&
                    items.map((item, idx) => {
                      return (
                        <div
                          role="presentation"
                          className={styles.hint}
                          key={idx}
                          onClick={(e) => handleOnClickHint(e.currentTarget)}
                          onKeyUp={(e) => handleOnClickHint(e.currentTarget)}
                        >
                          {item}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </>
        )}
        <label htmlFor={inputId} className={labelCursor}>
          {placeholder}
        </label>
      </div>
      {error && textError.length > 0 && <Error text={textError} />}
    </div>
  );
};

interface InputTextProps {
  placeholder: string;
  type?: string;
  name: string;
  value: string;
  setValue?: (text: string) => void;
  error?: boolean;
  textError?: string;
  required?: boolean;
  autoComplete?: string;
  items?: string[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default InputText;
