import { useNavigate } from 'react-router-dom';
import { Button } from '../../components';
import * as configProvider from '../../services/ConfigProvider';
import { pushPageViewDataLayer } from '../../services/googleTagManager';
import useTranslation from '../../utils/translation';
import LoginManual from '../LoginManual';
import LoginSocial from '../LoginSocial';
import styles from './styles.module.css';

const Login = () => {
  const config = configProvider.useConfig();
  const COUNTRY = configProvider.configGetCountry(config);
  const translate = useTranslation(COUNTRY);

  const navigate = useNavigate();

  const handleCreateAccount = () => {
    const params = new URLSearchParams(window.location.search);
    const urlRedirect = params.get('returnUrl');

    let createUrl = '/create';
    if (urlRedirect != null && urlRedirect != '') {
      createUrl = createUrl + '?returnUrl=' + encodeURIComponent(urlRedirect);
    }
    navigate(createUrl);
  };

  pushPageViewDataLayer('authentication - login', 'logged out');

  return (
    <div className={styles.socialLogin}>
      <Button
        id={styles.createAccount}
        text={translate['login-manual-button-create-account']}
        type="button"
        onClick={handleCreateAccount}
      />

      <LoginSocial />

      <div className={styles.loginDivider}>
        <span className={styles.textLoginDivider}>ou</span>
      </div>

      <LoginManual />
    </div>
  );
};

export default Login;
