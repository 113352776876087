import { useEffect } from 'react';

const useFacebookSDK = () => {
  useEffect(() => {
    ((d, s, id) => {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;

      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';

      if (fjs && fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      }
    })(document, 'script', 'facebook-jssdk');

    window.fbAsyncInit = () => {
      window.FB.init({
        appId: '576010465795031',
        cookie: true,
        xfbml: true,
        version: 'v12.0',
      });
    };
  }, []);
};

export default useFacebookSDK;
