import { ITranslationsType } from './types';

import TranslationBR from './translation-br';
import TranslationCL from './translation-cl';
import TranslationCO from './translation-co';

const translations: ITranslationsType = {
  BR: TranslationBR,
  CL: TranslationCL,
  CO: TranslationCO,
};

const useTranslation = (country: string) => {
  return translations[country];
};

export default useTranslation;
