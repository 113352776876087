/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button, InputText, Loading } from '../../components';
import styles from './styles.module.css';
import validation from '../../utils/validation';
import { useConfig } from '../../services/ConfigProvider';
import { forgotPassword } from '../../services/Login';
import useTranslation from '../../utils/translation';
import emailMask from '../../utils/helper/email-mask';
import {
  pushPageViewDataLayer,
  pushToDataLayer,
  sendErrorToGa,
} from '../../services/googleTagManager';

interface Props {
  closePopupFunction?: () => void;
}

const ForgotPass = (props: Props) => {
  const { closePopupFunction } = props;

  const [forgotPass, setForgotPass] = useState('');
  const [error, setError] = useState(false);
  const [valid, setValid] = useState(false);
  const [checkOurEmail, setCheckOurEmail] = useState(false);
  const [textErrorEmail, setTextErrorEmail] = useState('');
  const [textEmail, setTextEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const config = useConfig();
  const COUNTRY = config?.REACT_APP_COUNTRY || 'BR';
  const API_URL = config?.REACT_APP_LOGIN_ADAPTER_URL || '';

  useEffect(() => {
    pushPageViewDataLayer('login - esqueceu a senha', 'visualizado');
  }, []);

  const translate = useTranslation(COUNTRY);

  const validateLogin = useCallback(
    (text: string) => {
      if (validation.emailValidation(text)) return true;
      const numbers = onlyNumbers(text);
      if (COUNTRY === 'BR' && validation.cpfValidation(numbers)) return true;
      return false;
    },
    [COUNTRY],
  );

  const onlyNumbers = (text: string) => {
    return text.replace(/[^0-9]/g, '');
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const identify = e.target.value;

    setValid(validateLogin(identify));

    setForgotPass(e.target.value);
  };

  const handleValidateForgot = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    if (validateLogin(text)) {
      setValid(true);
      setError(false);
      return;
    }
    setTextErrorEmail(translate['forgot-password-form-validate']);
    setValid(false);
    setError(true);
    sendErrorToGa('login', 'esqueceu a senha', 'falha ao preencher e-mail');
    return;
  };

  const handleSubmitForm = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      setTextEmail(
        translate['forgot-password-send-email'].replaceAll(
          'email-sent',
          emailMask(forgotPass),
        ),
      );

      let username = forgotPass;
      if (forgotPass.indexOf('@') == -1) {
        username = onlyNumbers(forgotPass);
        setTextEmail(translate['forgot-password-send-cpf']);
      }

      await forgotPassword(API_URL, username);

      setLoading(false);

      setCheckOurEmail(true);
      setError(false);

      pushToDataLayer(
        'login',
        'esqueceu a senha',
        emailMask(forgotPass),
        'e-mail enviado com sucesso',
      );
    } catch (e: any) {
      setLoading(false);
      setCheckOurEmail(false);
      setError(true);
      setTextErrorEmail(translate['forgot-password-form-validate']);
      sendErrorToGa('login', 'esqueceu a senha', 'erro ao enviar e-mail');
      return;
    }
  };

  const handleClosePopup = () => {
    if (closePopupFunction != undefined) {
      closePopupFunction();
    }
    pushToDataLayer('login', 'esqueceu a senha', '', 'Popup closed');
  };

  return (
    <>
      <Loading visibled={loading} />
      <div className={styles.forgotpass}>
        <h1>{translate['forgot-password-my-password']}</h1>
        {!checkOurEmail && (
          <>
            <p>{translate['forgot-password-help-change']}</p>
            <form className={styles.form}>
              <InputText
                name="forgot"
                placeholder={translate['forgot-password-form-email']}
                type="email"
                error={error}
                textError={textErrorEmail}
                required={true}
                value={forgotPass}
                setValue={setForgotPass}
                onBlur={handleValidateForgot}
                onChange={handleTextChange}
              />
              <Button
                type="submit"
                text={translate['forgot-password-redefine']}
                disabled={!valid}
                onClick={handleSubmitForm}
              />
            </form>
          </>
        )}

        {checkOurEmail && (
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: textEmail,
              }}
            />
            <form className={styles.form}>
              <Button type="submit" text="Entendi" onClick={handleClosePopup} />
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default ForgotPass;
