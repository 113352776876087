import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import styles from './index.module.css';
import Login from './pages/Login';
import Footer from './components/Footer';
import CreateAccount from './pages/CreateAccount';
import RestorePassword from './pages/RestorePassword';
import googleTagManager from './services/googleTagManager';
import instana, { ievent } from './services/instana';
import { useConfig } from './services/ConfigProvider';
import { Brand, Loading } from './components';

import './App.css';

function App() {
  const config = useConfig();

  if (config && config.REACT_APP_ENVIRONMENT === 'production') {
    instana();
  }

  const COUNTRY = (config && config.REACT_APP_COUNTRY) || 'BR';
  window.localStorage.setItem('country', COUNTRY);

  if (!config) {
    return <Loading visibled={true} />;
  }

  //GA
  googleTagManager();
  ievent('page', 'login-front');

  return (
    <div className={`${styles.loginEntrarFbDesktopBr01}`}>
      <BrowserRouter>
        <Header />
        <section className={styles.brandLogin}>
          <div className={styles.brandLoginContent}>
            <Brand />

            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/create" element={<CreateAccount />} />
              <Route
                path="/restore-password/:restoreKey"
                element={<RestorePassword />}
              />
              <Route path="/*" element={<Login />} />
            </Routes>
          </div>
        </section>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
