const checkAutofill = (active: string) => {
  const elements = document.querySelectorAll('input');

  const fields = { email: false, password: false };

  elements.forEach((element) => {
    if (
      getComputedStyle(element).getPropertyValue('background-color') ==
      'rgb(232, 240, 254)'
    ) {
      if (element.parentElement != null) {
        element.parentElement.classList.add(active);
      }

      if (element.name === 'email') {
        fields.email = true;
      } else if (element.name === 'password') {
        fields.password = true;
      }

      return;
    } else {
      if (element.name == 'email' && element.value.length > 0)
        fields.email = true;
      if (element.name == 'password' && element.value.length > 0)
        fields.password = true;
    }
  });

  return fields.email && fields.password;
};
export default checkAutofill;
