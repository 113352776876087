/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { SocialButton } from '../../components';
import { socialLogin } from '../../services/Login';
import useFacebookSDK from '../../services/facebook';
import redirectHistory from '../../utils/redirect';
import SocialLoginButtonProps from './types';
import {
  pushToDataLayer,
  sendErrorToGa,
} from '../../services/googleTagManager';

const FacebookLoginButton: React.FC<SocialLoginButtonProps> = ({
  text,
  localRedirect,
  apiURL,
  associating,
}: SocialLoginButtonProps) => {
  useFacebookSDK();

  const handleLogin = () => {
    if (!window.FB) return;

    try {
      window.FB.login(
        (response: FBLoginStatusResponse) => {
          if (response.authResponse) {
            const accessToken = response.authResponse.accessToken;
            try {
              socialLogin(apiURL, accessToken, 'facebook').then(
                (socialResponse) => {
                  if (socialResponse.associated) {
                    pushToDataLayer(
                      'login',
                      'facebook',
                      '',
                      'social association success (facebook)',
                    );
                    redirectHistory(
                      `${localRedirect}`,
                      socialResponse.access_token,
                    );
                  } else {
                    pushToDataLayer(
                      'login',
                      'facebook',
                      '',
                      'associando dafiti (facebook)',
                    );
                    associating(true);
                    window.localStorage.setItem(
                      'token_social',
                      socialResponse.access_token,
                    );
                  }
                },
              );
            } catch (err: any) {
              sendErrorToGa(
                'login',
                'falha no login',
                'erro ao acessar login-adapter (facebook)',
              );
            }
          } else {
            sendErrorToGa(
              'login',
              'falha no login',
              'sem resposta do facebook',
            );
          }
        },
        { scope: 'public_profile,email' },
      );
    } catch (err: any) {
      // console.error('Erro ao tentar logar com Facebook', err);
      sendErrorToGa(
        'login',
        'falha no login',
        'Erro ao tentar logar com Facebook',
      );
    }
  };

  return (
    <div>
      <SocialButton text={text} provider="facebook" onClick={handleLogin} />
    </div>
  );
};

export default FacebookLoginButton;
