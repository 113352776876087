const redirectHistory = (url: string, token: string) => {
  // Obtendo um parâmetro da URL
  const oauthUrl = `/customer/account/oauth?`;
  const params = new URLSearchParams(window.location.search);
  const urlRedirect = params.get('returnUrl');
  const device = params.get('device');

  if (device && device == 'pwa') {
    return (window.location.href = urlRedirect + '&token=' + token);
  } else {
    if (urlRedirect) {
      window.location.href =
        url + oauthUrl + 'returnUrl=' + urlRedirect + '&access_token=' + token;
    } else {
      window.location.href =
        url +
        oauthUrl +
        'uri=/customer/account/list/' +
        '&access_token=' +
        token;
    }
  }
};

export default redirectHistory;
