import React from 'react';
import { SocialButton } from '../../components';
import SocialLoginButtonProps from './types';
import AppleLoginSDK from '../../services/apple';
import { socialLogin } from '../../services/Login';
import redirectHistory from '../../utils/redirect';
import {
  pushToDataLayer,
  sendErrorToGa,
} from '../../services/googleTagManager';

interface AppleLoginButtonProps extends SocialLoginButtonProps {
  clientId: string;
}

const AppleLoginButton: React.FC<AppleLoginButtonProps> = (props) => {
  const { text, localRedirect, apiURL, clientId, associating } = props;
  AppleLoginSDK(clientId);

  const handleAppleSignIn = () => {
    window.AppleID.auth
      .signIn()
      .then(async (response) => {
        const responseLogin = await socialLogin(
          apiURL,
          response.authorization.id_token,
          'apple',
        );
        if (responseLogin.associated) {
          pushToDataLayer(
            'login',
            'apple',
            '',
            'social association success (apple)',
          );
          redirectHistory(`${localRedirect}`, responseLogin.access_token);
        } else {
          pushToDataLayer('login', 'apple', '', 'associando dafiti (apple)');
          associating(true);
          window.localStorage.setItem(
            'token_social',
            responseLogin.access_token,
          );
        }
      })
      .catch((error) => {
        sendErrorToGa('login', 'falha no login', 'login social (apple)');
        console.error('Erro ao tentar logar com Apple:', error);
      });
  };

  return (
    <div>
      <SocialButton text={text} provider="apple" onClick={handleAppleSignIn} />
    </div>
  );
};

export default AppleLoginButton;
