import PropTypes from 'prop-types';
import Divider from '../Divider';
import styles from './styles.module.css';

const Notification = ({
  showLink = true,
  showTitle = true,
  animateMobile = true,
  status = 'success',
  title = 'Label Title',
  message = 'Lorem ipsum dolor sit amet',
  onclick = () => {},
}) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    onclick();
  };

  const handleKeyDown = () => {
    onclick();
  };

  return (
    <div
      role="presentation"
      className={
        styles.notification +
        ' ' +
        styles[status] +
        (animateMobile ? ' ' + styles.animateMobile : '')
      }
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <div className={styles['interface-info']}>
        <span className={styles['icon']}>!</span>
      </div>
      <div className={styles.content}>
        {showTitle && <div className={styles['label-title']}>{title}</div>}

        <span className={styles.label}>{message}</span>
        {showLink && (
          <div className={styles.link}>
            <div className={styles['text-wrapper']}>Link</div>
            <Divider
              className={`${status === 'sucess' ? 'class' : status === 'error' ? 'class-2' : 'class-3'}`}
              pattern="dark"
              state="solid"
            />
          </div>
        )}
      </div>
    </div>
  );
};

Notification.propTypes = {
  showLink: PropTypes.bool,
  showTitle: PropTypes.bool,
  status: PropTypes.oneOf(['sucess', 'error', 'info']),
  union: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  onclick: PropTypes.func,
};

export default Notification;
