import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const LinkButton = (props: LinkButtonProps) => {
  const { text, url, onclick } = props;

  if (url !== undefined && url.length > 0) {
    return (
      <Link className={styles.buttonTertiary1} to={url}>
        <div className={styles.content2}>
          <div className={styles.button2}>{text}</div>
          <div className={styles.divider1} />
        </div>
      </Link>
    );
  }

  const handleOnClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (onclick) {
      onclick();
    }
  };

  return (
    <Link
      className={`${styles.buttonTertiary1}`}
      to="/"
      onClick={handleOnClick}
    >
      <div className={styles.content2}>
        <div className={styles.button2}>{text}</div>
        <div className={styles.divider1} />
      </div>
    </Link>
  );
};

interface LinkButtonProps {
  text: string;
  url?: string | undefined;
  onclick?: () => void;
}

export default LinkButton;
