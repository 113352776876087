import { FunctionComponent, useState } from 'react';
import styles from './styles.module.css';
import * as configProvider from '../../services/ConfigProvider';
import useTranslation from '../../utils/translation';
import FacebookLoginButton from './facebookLogin';
import AppleLoginButton from './appleLogin';
import GoogleLoginButton from './googleLogin';
import { LinkButton } from '../../components';

const LoginSocial: FunctionComponent = () => {
  const config = configProvider.useConfig();
  const COUNTRY = configProvider.configGetCountry(config);
  const API_URL = configProvider.configGetLoginAdapterUrl(config);
  const localRedirect = configProvider.configGetAliceHost(config);

  const appleSocial = configProvider.configGetSocialApple(config);
  const googleSocial = configProvider.configGetSocialGoogle(config);
  const facebookSocial = configProvider.configGetSocialFacebook(config);
  const appleClientId = configProvider.configGetServiceLoginApple(config);

  const [associate, setAssociate] = useState<boolean>(false);
  const translate = useTranslation(COUNTRY);

  const handleCancelSocialAssociate = () => {
    setAssociate(false);

    const tokenSocial = window.localStorage.getItem('token_social');
    if (tokenSocial !== null) {
      window.localStorage.deleteItem('token_social');
    }
  };

  const handleTryLogin = () => {
    setAssociate(false);
  };

  return (
    <div
      className={`${styles.socialLoginContent} ${associate ? styles.associate : ''}`}
    >
      <div
        role="presentation"
        className={`${styles.backgroundBlur} ${associate ? styles.associate : ''}`}
        onClick={handleTryLogin}
      />
      <div className={styles.socialLoginButtonHolder}>
        <div className={styles.socialLoginHeader}>
          <h1 className={styles.entrarComRede}>
            {translate['login-social-title']}
          </h1>
        </div>
        <div className={styles.socialLoginButtons}>
          {facebookSocial && (
            <FacebookLoginButton
              apiURL={API_URL}
              text={translate['login-social-button-facebook']}
              localRedirect={localRedirect}
              associating={setAssociate}
            />
          )}
          {appleSocial && (
            <AppleLoginButton
              apiURL={API_URL}
              text={translate['login-social-button-apple']}
              localRedirect={localRedirect}
              clientId={appleClientId}
              associating={setAssociate}
            />
          )}
          {googleSocial && (
            <GoogleLoginButton
              apiURL={API_URL}
              text={translate['login-social-button-google']}
              localRedirect={localRedirect}
              associating={setAssociate}
            />
          )}
        </div>
        <div className={styles.socialLoginDescription}>
          <div className={styles.associeUmaConta}>
            {translate['login-social-message']}
          </div>
        </div>
      </div>

      {associate && (
        <div
          className={`${styles.bottomSheet} ${associate ? styles.associate : ''}`}
        >
          <div className={styles.dragbar}>
            <div className={styles.dragbarGrab} />
          </div>
          <div className={styles.bottomSheetHeader}>
            <button className={styles.closeX} onClick={handleTryLogin} />
            <h1 className={styles.bottomSheetTitle}>
              {translate['login-social-title']}
            </h1>
          </div>
          <div className={styles.bottomSheetDescription}>
            <div className={styles.bottomSheetContent}>
              <h2>{translate['login-social-associate-title']}</h2>
              <div className={styles.associateDescription}>
                {translate['login-social-associate-description']}
              </div>
            </div>
            <LinkButton
              text={translate['login-social-associate-cancel']}
              onclick={handleCancelSocialAssociate}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginSocial;
