import React, { useState } from 'react';
import ReactInputMask from 'react-input-mask';
import Error from '../Error';
import styles from '../InputText/styles.module.css';

interface InputMaskedInterface {
  mask: string;
  name: string;
  value: string;
  placeholder: string;
  error?: boolean;
  textError?: string;
  inputMode?:
    | 'none'
    | 'search'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
}

const InputMasked = ({
  mask,
  name,
  value,
  placeholder,
  error = false,
  textError = '',
  inputMode = 'none',
  onChange,
  onBlur,
}: InputMaskedInterface) => {
  const [isActive, setIsActive] = useState(false);

  const inputId =
    'inputid_' +
    name.replaceAll(' ', '') +
    styles.inputText.replace('styles_inputText', '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (text !== '') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    if (onChange) {
      onChange(e);
    }
  };

  const containerId = 'c__' + name.replaceAll(' ', '');
  let cid = '';
  if (styles[containerId]) {
    cid = ' ' + styles[containerId];
  }

  return (
    <div className={styles.inputTextContent + cid}>
      <div
        className={
          styles.inputText +
          ' ' +
          (error ? styles.error : '') +
          (isActive ? ' ' + styles.active : '')
        }
      >
        <ReactInputMask
          mask={mask}
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          id={inputId}
          inputMode={inputMode}
          type="text"
        />
        <label htmlFor={inputId}>{placeholder}</label>
      </div>
      {error && textError.length > 0 && <Error text={textError} />}
    </div>
  );
};

export default InputMasked;
