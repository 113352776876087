import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';

import { ServerSettings } from './types';

const ConfigContext = createContext<ServerSettings | null>(null);

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [config, setConfig] = useState<ServerSettings | null>(null);

  const fetchConfig = useCallback(async () => {
    const response = await fetch('/settings.json');
    const data = await response.json();
    setConfig(data);
  }, []);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = () => {
  return useContext(ConfigContext);
};

export const configGetAliceHost = (config: ServerSettings | null) => {
  return config?.REACT_APP_ALICE_HOST ? `${config.REACT_APP_ALICE_HOST}` : '';
};

export const configGetCountry = (config: ServerSettings | null) => {
  return config?.REACT_APP_COUNTRY || 'BR';
};

export const configGetLoginAdapterUrl = (config: ServerSettings | null) => {
  return config?.REACT_APP_LOGIN_ADAPTER_URL || '';
};

export const configGetRecaptchaKey = (config: ServerSettings | null) => {
  return config?.REACT_APP_RECAPTCHA || '';
};

export const configGetSocialApple = (config: ServerSettings | null) => {
  return (config?.REACT_APP_SOCIAL_APPLE || 'true') === 'true';
};

export const configGetSocialGoogle = (config: ServerSettings | null) => {
  return (config?.REACT_APP_SOCIAL_GOOGLE || 'true') === 'true';
};

export const configGetSocialFacebook = (config: ServerSettings | null) => {
  return (config?.REACT_APP_SOCIAL_FACEBOOK || 'true') === 'true';
};

export const configGetServiceLoginApple = (config: ServerSettings | null) => {
  return config?.REACT_APP_SERVICE_LOGIN_APPLE || '';
};
