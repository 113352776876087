/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  GoogleOAuthProvider,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from '@react-oauth/google';
import { SocialButton } from '../../components';
import { socialLogin } from '../../services/Login';
import redirectHistory from '../../utils/redirect';
import SocialLoginButtonProps from './types';
import {
  pushToDataLayer,
  sendErrorToGa,
} from '../../services/googleTagManager';

const GoogleLoginButton: React.FC<SocialLoginButtonProps> = (props) => {
  return (
    <GoogleOAuthProvider clientId="212209860394-snq03fbhdfkio5sljlbrr46c1cn9lj7f.apps.googleusercontent.com">
      <CreateButton {...props} />
    </GoogleOAuthProvider>
  );
};
const CreateButton: React.FC<SocialLoginButtonProps> = (props) => {
  const { text, apiURL, localRedirect, associating } = props;
  const [emailHint, setEmailHint] = useState<string | undefined>(undefined); // Define o estado como string | undefined
  // Configuração do Google One Tap Login
  useGoogleOneTapLogin({
    onSuccess: async (credentialResponse) => {
      const { credential } = credentialResponse;
      if (!credential) {
        sendErrorToGa(
          'login',
          'falha no login',
          'erro ao acessar credentials (google)',
        );
        return;
      }

      try {
        const decodedCredential = JSON.parse(atob(credential.split('.')[1]));
        const email = decodedCredential?.email;
        setEmailHint(email);
        loginOneTap();
      } catch (err: any) {
        sendErrorToGa(
          'login',
          'falha no login',
          'erro ao acessar login-adapter (google)',
        );
      }
    },
    onError: () => {
      sendErrorToGa(
        'login',
        'falha no login',
        'erro ao acessar onetap (google)',
      );
    },
  });

  const login = useGoogleLogin({
    onSuccess: async (response: GoogleLoginResponse) => {
      handleLoginSuccess(response, apiURL, localRedirect, associating);
    },
    onError: (error: any) => {
      console.error('Erro ao tentar logar com Google', error);
      sendErrorToGa('login', 'falha no login', 'login social (google)');
    },
  });

  const loginOneTap = useGoogleLogin({
    flow: 'implicit',
    hint: emailHint,
    prompt: 'select_account',
    onSuccess: async (response: GoogleLoginResponse) => {
      handleLoginSuccess(response, apiURL, localRedirect, associating);
    },
    onError: (error: any) => {
      // LoginOneTap('select_account');
      console.error(error);
      sendErrorToGa('login', 'falha no login', 'login social (google)');
    },
  });

  return <SocialButton text={text} provider="google" onClick={() => login()} />;
};

// Função para gerenciar o sucesso do login
const handleLoginSuccess = async (
  response: GoogleLoginResponse,
  apiURL: string,
  localRedirect: string,
  associating: (val: boolean) => void,
) => {
  try {
    const accessToken = await socialLogin(
      apiURL,
      response.access_token,
      'gplus',
    );
    if (accessToken.associated) {
      pushToDataLayer(
        'login',
        'google',
        '',
        'social association success (google)',
      );
      redirectHistory(`${localRedirect}`, accessToken.access_token);
    } else {
      associating(true);
      window.localStorage.setItem('token_social', accessToken.access_token);
      pushToDataLayer('login', 'google', '', 'associando dafiti (google)');
    }
  } catch (err) {
    sendErrorToGa(
      'login',
      'falha no login',
      'erro ao acessar login-adapter (google)',
    );
  }
};

export default GoogleLoginButton;
