import { useContext, useEffect, useState } from 'react';
import RecaptchaContext from '../../hooks/RecaptchaContext';
import useReCaptcha from '../../hooks/useReCaptcha';
import { useConfig } from '../../services/ConfigProvider';
import LoginSocial from '../LoginSocial';
import CreateAccountBR from './CreateAccountBR';
import CreateAccountCL from './CreateAccountCL';
import CreateAccountCO from './CreateAccountCO';
import styles from './styles.module.css';

const CreateAccount = () => {
  const config = useConfig();
  const COUNTRY = (config && config.REACT_APP_COUNTRY) || 'BR';
  const recaptchaPublicKey = config?.REACT_APP_RECAPTCHA || '';

  const [recaptchaToken, setRecaptchaToken] = useState('');

  const { recaptchaReady } = useReCaptcha(recaptchaPublicKey);

  const recaptcha = useContext(RecaptchaContext);

  useEffect(() => {
    const renewRecaptchaToken = async () => {
      if (recaptchaReady) {
        const recaptchaToken =
          await recaptcha.executeReCaptcha(recaptchaPublicKey);
        setRecaptchaToken(recaptchaToken);
      }
    };

    renewRecaptchaToken();
  }, [recaptcha, recaptchaPublicKey, recaptchaReady, setRecaptchaToken]);

  return (
    <div className={styles.socialLogin}>
      <LoginSocial />

      <div className={styles.loginDivider}>
        <span className={styles.textLoginDivider}>ou</span>
      </div>

      <RecaptchaContext.Provider
        value={{ executeReCaptcha: recaptcha.executeReCaptcha, recaptchaToken }}
      >
        {COUNTRY === 'BR' && <CreateAccountBR />}
        {COUNTRY === 'CL' && <CreateAccountCL />}
        {COUNTRY === 'CO' && <CreateAccountCO />}
      </RecaptchaContext.Provider>
    </div>
  );
};

export default CreateAccount;
